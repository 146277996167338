import React from 'react';

import { Back } from './Back';
import { Photo } from './Photo';
import { Switcher } from './Switcher';

import { sliderContainer } from './containers';

type Props = {
    ms?: number;
};

export function Slider(props: Props) {
    const { ms } = props;

    if (ms) {
        sliderContainer.setDuration(ms);
    }

    return (
        <div
            style={{
                position: 'relative',
                paddingBottom: 20,
                overflow: 'hidden',
            }}
        >
            <Photo />
            <Back />
            <Switcher />
        </div>
    );
}
