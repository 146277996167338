import React from 'react';

import { sliderBack } from '../../assets';

export function Back() {
    return (
        <div
            style={{
                position: 'relative',
                top: 0,
            }}
        >
            <img src={sliderBack} alt="slider_back" width="100%" />
        </div>
    );
}
