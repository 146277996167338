import React from 'react';

import { lighterThan, Async, darkerThan, colorAddOpacity } from '@lochmatij/modules-js.utilities';

import { Color, flex, padding, Sizes } from '../styles';

const clickAnimationTime = 2; // 200 ms
const baseAnimationTime = 5; // 500 ms

export enum ButtonSizes {
    Small = 'small',
    Medium = 'medium',
    Big = 'big',
}

type Props = {
    onClick: () => void;
    disabled?: boolean;
    title: string;
    primaryColor: string;
    secondaryColor: string;
    innerPadding?: ButtonSizes;
    height?: ButtonSizes;
};

export function Button(props: Props) {
    const { onClick, disabled, primaryColor, secondaryColor, title, innerPadding, height } = props;

    const [focused, setFocused] = React.useState(false);
    const [hovered, setHovered] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);

    const click = React.useCallback(async () => {
        if (disabled) {
            return;
        }
        setClicked(true);
        await Async.timeout(clickAnimationTime * 100);
        onClick();
        setClicked(false);
    }, [disabled, onClick]);

    const background = React.useMemo(() => {
        if (disabled) {
            return colorAddOpacity(primaryColor, 0.3);
        }
        if (clicked) {
            return secondaryColor;
        }
        return hovered ? lighterThan(primaryColor) : primaryColor;
    }, [clicked, disabled, hovered, primaryColor, secondaryColor]);

    const paddingH = React.useMemo(() => {
        if (innerPadding === ButtonSizes.Small) {
            return padding.horizontal(Sizes.XS);
        }
        if (innerPadding === ButtonSizes.Big) {
            return padding.horizontal(Sizes.XXL);
        }
        return padding.horizontal(Sizes.M);
    }, [innerPadding]);

    const buttonHeight = React.useMemo(() => {
        if (height === ButtonSizes.Small) {
            return 24;
        }
        if (height === ButtonSizes.Big) {
            return 48;
        }
        return 36;
    }, [height]);

    return (
        <div
            onClick={click}
            onKeyDown={click}
            role="button"
            tabIndex={-1}
            style={{
                ...flex.center,
                backgroundColor: background,
                transition: `all .${clicked ? clickAnimationTime : baseAnimationTime}s ease`,
                border: focused ? `1px solid ${darkerThan(background)}` : `1px solid ${background}`,
                borderRadius: 6,
                height: buttonHeight,
                ...paddingH,
                cursor: disabled ? 'default' : 'pointer',
                color: disabled ? colorAddOpacity(Color.Black, 0.5) : Color.Black,
            }}
            onFocus={() => {
                if (disabled) {
                    return;
                }
                setFocused(true);
            }}
            onBlur={() => setFocused(false)}
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {title}
        </div>
    );
}
