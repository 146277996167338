import React, { useEffect } from 'react';

import { YouTubeVideo } from '../components';
import { useElementWidth, useScreenWidth } from '../components/hooks';
import { Color, flex, font, margin, padding, Sizes } from '../components/styles';

import { BasePagePattern } from './PagePattern';

import { about } from '../constants';

const styles = {
    title: {
        ...flex.rowLeft,
        ...margin.vertical(Sizes.XXL),
    } as React.CSSProperties,
    paragraph: {
        ...font.s,
        ...font.light,
        ...flex.rowLeft,
        textAlign: 'left',
        ...margin.bottom(Sizes.XL),
    } as React.CSSProperties,
    item: {
        ...flex.column,
        justifyContent: 'flex-start',
        width: 250,
        ...margin.horizontal(Sizes.L),
        ...margin.vertical(Sizes.XL),
    } as React.CSSProperties,
    itemTitle: {
        fontSize: 45,
        ...font.heavy,
    } as React.CSSProperties,
    itemContent: {
        ...font.s,
        ...font.light,
    } as React.CSSProperties,
};

export function About() {
    const { ref, width } = useElementWidth();
    const { width: screen } = useScreenWidth();

    useEffect(() => {
        // scroll page to top, because sometimes after render u located at the middle
        window.scrollTo(0, 0);
    }, []);

    const computedStyle = React.useMemo(() => {
        if (screen > 1000) {
            return {
                flex: flex.rowCenter,
                size: '50%',
            };
        }
        return {
            flex: flex.columnCenter,
            size: '100%',
        };
    }, [screen]);

    const style = React.useMemo(() => {
        if (width > 768) {
            return {
                width: 715,
                height: 390,
            };
        }
        const k = (width * 0.9) / 715;
        return {
            width: 715 * k,
            height: 390 * k,
        };
    }, [width]);

    return (
        <BasePagePattern>
            <div
                style={{
                    ...computedStyle.flex,
                    ...margin.all(Sizes.XL),
                    height: '100%',
                }}
            >
                <div
                    style={{
                        maxWidth: 700,
                        width: computedStyle.size,
                        ...padding.horizontal(Sizes.L),
                    }}
                >
                    <div style={styles.title}>О нас</div>
                    <div style={styles.paragraph}>{about.paragraph1}</div>
                    <div style={styles.paragraph}>{about.paragraph2}</div>
                    <div style={styles.paragraph}>{about.paragraph3}</div>
                </div>
                <div
                    ref={ref}
                    style={{
                        width: computedStyle.size,
                        ...flex.center,
                        ...margin.vertical(Sizes.M),
                    }}
                >
                    <YouTubeVideo id="8CDGtY7_28U" width={style.width} height={style.height} />
                </div>
            </div>
            <div
                style={{
                    minHeight: 267,
                    width: '100%',
                    backgroundColor: Color.Grey,
                    ...flex.columnCenter,
                }}
            >
                <div
                    style={{
                        ...flex.row,
                        width: '80%',
                        flexDirection: screen > 600 ? 'row' : 'column',
                        alignItems: screen > 600 ? 'flex-start' : 'center',
                        justifyContent: 'space-around',
                    }}
                >
                    <div style={styles.item}>
                        <div style={styles.itemTitle}>1980</div>
                        <div style={styles.itemContent}>год основания фабрики</div>
                    </div>
                    <div style={styles.item}>
                        <div style={styles.itemTitle}>2004</div>
                        <div style={styles.itemContent}>
                            год начала производства мебели для IKEA
                        </div>
                    </div>
                    <div style={styles.item}>
                        <div style={styles.itemTitle}>20</div>
                        <div style={styles.itemContent}>
                            лет мы производили мебель, которая продавалась в магазинах IKEA по всему
                            миру
                        </div>
                    </div>
                </div>
            </div>
        </BasePagePattern>
    );
}
