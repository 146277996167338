import type React from 'react';

import type { Sizes } from './constants';
import type { Offsets } from './types';

const base = 4;

function all(size: Sizes): React.CSSProperties {
    return {
        margin: base * size,
    };
}

function horizontal(size: Sizes): React.CSSProperties {
    return {
        marginRight: base * size,
        marginLeft: base * size,
    };
}

function vertical(size: Sizes): React.CSSProperties {
    return {
        marginTop: base * size,
        marginBottom: base * size,
    };
}

function left(size: Sizes): React.CSSProperties {
    return {
        marginLeft: base * size,
    };
}

function right(size: Sizes): React.CSSProperties {
    return {
        marginRight: base * size,
    };
}

function top(size: Sizes): React.CSSProperties {
    return {
        marginTop: base * size,
    };
}

function bottom(size: Sizes): React.CSSProperties {
    return {
        marginBottom: base * size,
    };
}

export const margin: Offsets = {
    all,
    horizontal,
    vertical,
    left,
    right,
    top,
    bottom,
};
