import React from 'react';

import { font, margin, padding, Sizes, blockMaxWidth, flex } from '../components/styles';

import { BasePagePattern } from './PagePattern';

import { about } from '../constants';

import { catalogService } from '../services/Catalog';
import { ItemCard } from '../components/Catalog/ItemCard';

const styles = {
    paragraph: {
        ...font.s,
        ...font.light,
        ...margin.vertical(Sizes.M),
        textAlign: 'left',
        width: '90%',
    } as React.CSSProperties,
};

export function Catalog() {
    return (
        <BasePagePattern>
            <div
                style={{
                    maxWidth: blockMaxWidth + 50,
                }}
            >
                <div
                    style={{
                        marginBottom: 100,
                        ...padding.top(Sizes.XXL),
                        ...flex.rowCenter,
                        flexWrap: 'wrap',
                    }}
                >
                    {catalogService.items.map(x => (
                        <ItemCard item={x} key={`item-card_key:${x.id}`} />
                    ))}
                </div>
                <div style={{ ...flex.columnCenter, ...padding.bottom(Sizes.XXL) }}>
                    <div style={{ ...styles.paragraph }}>{about.paragraph1}</div>
                    <div style={{ ...styles.paragraph }}>{about.paragraph2}</div>
                    <div style={{ ...styles.paragraph }}>{about.paragraph3}</div>
                </div>
            </div>
        </BasePagePattern>
    );
}
