import React from 'react';

import { Color } from '../styles';

import { ButtonWrap } from './ButtonWrap';

type Props = {
    onClick: () => void;
    title: string;
};

export function InlineLink(props: Props) {
    const { onClick, title } = props;

    const click = React.useCallback(() => {
        onClick();
    }, [onClick]);

    return (
        <ButtonWrap
            onClick={click}
            style={{
                cursor: 'pointer',
                color: Color.Link,
                display: 'inline',
            }}
        >
            {title}
        </ButtonWrap>
    );
}
