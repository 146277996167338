export enum Sizes {
    None = 0,
    XS = 1,
    S = 2,
    M = 3,
    L = 4,
    XL = 6,
    XXL = 8,
    XXXL = 12,
}

export const blockMaxWidth = 1100;
