import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { logo } from '../../assets';

import { Routes } from '../../constants';

import { useScreenWidth } from '../hooks';

import { margin, Sizes } from '../styles';

import { ButtonWrap } from '../Buttons';

export function Logo() {
    const navigate = useNavigate();
    const location = useLocation();

    const { width } = useScreenWidth();

    const active = React.useMemo(() => {
        return location.pathname !== Routes.Main;
    }, [location.pathname]);

    const click = React.useCallback(() => {
        if (!active) {
            // no need to navigate
            return;
        }
        navigate('/');
    }, [active, navigate]);

    if (width < 650) {
        return null;
    }

    return (
        <ButtonWrap
            onClick={click}
            style={{
                ...margin.all(Sizes.S),
                cursor: active ? 'pointer' : 'default',
            }}
        >
            <img src={logo} className="App-logo" alt="logo" />
        </ButtonWrap>
    );
}
