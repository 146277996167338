import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useScreenWidth } from '../components/hooks';
import { Gallery, ToCart, Tabs, CatalogPreview } from '../components';
import { flex, font, margin, padding, Sizes, blockMaxWidth, Color } from '../components/styles';

import { catalogService } from '../services/Catalog';

import type { Tab } from '../components';
import { BasePagePattern } from './PagePattern';

const styles = {
    detailsItem: {
        ...margin.bottom(Sizes.XS),
        ...font.s,
        ...font.light,
        textAlign: 'left',
    } as React.CSSProperties,
    detailsHeader: {
        ...font.heavy,
        display: 'inline',
    } as React.CSSProperties,
};

export const CatalogItem = observer(function CatalogItem() {
    const { itemId } = useParams();
    const navigate = useNavigate();
    const { width } = useScreenWidth();

    const item = React.useMemo(() => {
        return itemId ? catalogService.getBedWithId(itemId) : undefined;
    }, [itemId]);

    const tabs: Tab[] = React.useMemo(() => {
        if (!item) {
            return [];
        }

        return [
            {
                key: 'desc',
                title: 'Описание',
                paragraphs: item.descriptionLong,
            },
            // {
            //     key: 'instruction',
            //     title: 'Инструкция',
            //     paragraphs: [
            //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est.',
            //     ],
            // },
        ];
    }, [item]);

    useEffect(() => {
        if (!item) {
            navigate('/catalog');
        }
        // scroll page to top, because sometimes after render u located at the middle
        window.scrollTo(0, 0);
        catalogService.setActive(item?.id);
    }, [item, navigate]);

    if (!item) {
        return null;
    }

    const { art, title, descriptionShort, priceStr, availability, images } = item;

    return (
        <BasePagePattern>
            <div style={{ ...flex.center, ...margin.bottom(Sizes.XXXL) }}>
                <div
                    style={{
                        maxWidth: blockMaxWidth,
                        ...(width > 800 ? flex.row : flex.column),
                    }}
                >
                    <div
                        style={{
                            ...margin.bottom(Sizes.XXL),
                            ...flex.rowCenter,
                        }}
                    >
                        <Gallery images={images} />
                    </div>
                    <div>
                        <div
                            style={{
                                width: '90%',
                                marginLeft: '5%',
                            }}
                        >
                            <div
                                style={{
                                    ...font.l,
                                    textAlign: 'left',
                                    ...margin.bottom(Sizes.M),
                                }}
                            >
                                {title}
                            </div>
                            <div
                                style={{
                                    ...margin.vertical(Sizes.M),
                                    ...font.xs,
                                    textAlign: 'left',
                                }}
                            >
                                <div style={{ ...margin.bottom(Sizes.S) }}>В комплект входят:</div>
                                <div>Матрас: нет</div>
                                <div>Ящик для белья: нет</div>
                            </div>
                            <div
                                style={{
                                    maxWidth: 450,
                                    ...margin.vertical(Sizes.M),
                                    ...font.xs,
                                    textAlign: 'left',
                                }}
                            >
                                {descriptionShort}
                            </div>
                            <div
                                style={{
                                    ...margin.vertical(Sizes.XXL),
                                    ...flex.row,
                                    ...padding.bottom(Sizes.XXL),
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderBottomWidth: 1,
                                    borderBottomStyle: 'solid',
                                    borderBlockEndColor: Color.Grey,
                                    maxWidth: 450,
                                }}
                            >
                                <div
                                    style={{
                                        ...font.l,
                                    }}
                                >
                                    {priceStr}
                                </div>
                                <ToCart item={item} />
                            </div>
                            <div style={{ ...margin.bottom(Sizes.XXL) }}>
                                <div style={{ ...styles.detailsItem }}>
                                    Артикул:&nbsp;
                                    <div style={{ ...styles.detailsHeader }}>{art}</div>
                                </div>
                                <div style={{ ...styles.detailsItem }}>
                                    Наличие:&nbsp;
                                    <div style={{ ...styles.detailsHeader }}>{availability}</div>
                                </div>
                            </div>
                            <Tabs tabs={tabs} />
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: Color.Grey,
                    ...padding.top(Sizes.XXXL),
                    width: '100%',
                }}
            >
                <div
                    style={{
                        ...font.l,
                        ...margin.vertical(Sizes.XXL),
                    }}
                >
                    Другие товары
                </div>
                <div style={{ marginBottom: 130 }}>
                    <CatalogPreview />
                </div>
            </div>
        </BasePagePattern>
    );
});
