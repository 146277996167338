import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { cartIco2 } from '../../assets';
import { Routes } from '../../constants';

import { Availability, CatalogItemStore } from '../../services/Catalog';
import { ImageButton } from '../Buttons';

import { margin, padding, flex, font, Sizes, Color } from '../styles';

type Props = {
    item: CatalogItemStore;
};

export function ItemCard({ item }: Props) {
    const navigate = useNavigate();
    const location = useLocation();

    const { id, art, descriptionPreview, priceStr, marketPlaceLink, preview, availability } = item;

    const active = React.useMemo(() => {
        return location.pathname !== `${Routes.CatalogItemPrefix}${id}`;
    }, [id, location.pathname]);

    const click = React.useCallback(() => {
        if (!active) {
            return;
        }
        navigate(`/catalog-item/${id}`);
    }, [active, id, navigate]);

    const buttonClick = React.useCallback(() => {
        const win = window.open(marketPlaceLink, '_blank');
        if (win) {
            win.focus();
        }
    }, [marketPlaceLink]);

    return (
        <div
            style={{
                width: 302,
                ...margin.all(Sizes.L),
                ...padding.all(Sizes.XL),
                ...flex.columnCenter,
                borderRadius: 6,
                boxShadow: '10px 10px 20px 1px rgba(0, 0, 0, .1)',
            }}
        >
            <div
                onClick={click}
                onKeyDown={click}
                role="button"
                tabIndex={-1}
                style={{
                    cursor: active ? 'pointer' : 'default',
                }}
            >
                <img
                    src={preview}
                    alt="Preview"
                    width={300}
                    height={250}
                    style={margin.bottom(Sizes.XL)}
                />
            </div>
            <div
                style={{
                    ...font.xs,
                    ...font.f13,
                    color: Color.Secondary,
                    ...margin.bottom(Sizes.M),
                }}
            >
                {art}
            </div>
            <div style={{ ...font.s, ...font.light }}>{descriptionPreview}</div>
            <div
                style={{
                    ...flex.rowSpread,
                    ...margin.top(Sizes.L),
                    width: '100%',
                    alignItems: 'baseline',
                }}
            >
                <div style={{ ...font.m }}>{priceStr}</div>
                <ImageButton
                    image={cartIco2}
                    disabled={availability !== Availability.available}
                    onClick={buttonClick}
                    primaryColor={Color.Primary}
                    secondaryColor={Color.LightGrey}
                    paddingV={Sizes.M}
                />
            </div>
        </div>
    );
}
