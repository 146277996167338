import React from 'react';

import { useElementWidth } from '../hooks';

type Props = {
    src: string;
    maxWidth?: number;
};

export function AdaptiveImage(props: Props) {
    const { src, maxWidth } = props;

    const { ref, width } = useElementWidth();

    return (
        <div
            ref={ref}
            style={{
                width: '100%',
                maxWidth,
            }}
        >
            <img src={src} alt="adaptive" width={width} />
        </div>
    );
}
