export type HexColor = string;

export type RGBColor = {
    r: number;
    g: number;
    b: number;
};

export type RGBAColor = string;

const hexIncorrect = new Error('Hex color invalid format');

function isHexValid(color: HexColor): boolean {
    if (color[0] !== '#') {
        return false;
    }
    if (color.length !== 7) {
        return false;
    }
    return true;
}

function isRgbString(color: HexColor): boolean {
    return color.slice(0, 3) === 'rgb';
}

export function colorHexToRGB(color: HexColor): RGBColor {
    if (!isHexValid(color)) {
        console.log('Failed to convert to RGB', color);
        throw hexIncorrect;
    }

    return {
        r: parseInt(color.slice(1, 3), 16),
        g: parseInt(color.slice(3, 5), 16),
        b: parseInt(color.slice(5, 7), 16),
    };
}

export function colorRGBToHex(color: RGBColor): HexColor {
    const numToStr = (x: number) => {
        if (x > 9) {
            return x.toString(16);
        }
        return `0${x.toString(16)}`;
    };

    return `#${numToStr(color.r)}${numToStr(color.g)}${numToStr(color.b)}`;
}

export function colorOffset(startColor: HexColor, endColor: HexColor, offset: number): HexColor {
    if (!isHexValid(startColor) || !isHexValid(endColor)) {
        console.log('Failed to calculate offset', startColor, endColor);
        throw hexIncorrect;
    }

    const { r: r1, g: g1, b: b1 } = colorHexToRGB(startColor);
    const { r: r2, g: g2, b: b2 } = colorHexToRGB(endColor);

    const average = (x: number, y: number, off: number): number => {
        return Math.floor(x + ((y - x) * off) / 100);
    };

    const result = {
        r: average(r1, r2, offset),
        g: average(g1, g2, offset),
        b: average(b1, b2, offset),
    };

    return colorRGBToHex(result);
}

export function darkerThan(color: HexColor): HexColor {
    if (isRgbString(color)) {
        // For now return as is
        return color;
    }

    if (!isHexValid(color)) {
        console.log('Failed to get darker than', color);
        throw hexIncorrect;
    }

    const { r, g, b } = colorHexToRGB(color);

    const step = 25;

    const result = {
        r: r - step > 0 ? r - step : 0,
        g: g - step > 0 ? g - step : 0,
        b: b - step > 0 ? b - step : 0,
    };

    return colorRGBToHex(result);
}

export function lighterThan(color: HexColor): HexColor {
    if (!isHexValid(color)) {
        console.log('Failed to get lighter than', color);
        throw hexIncorrect;
    }

    const { r, g, b } = colorHexToRGB(color);

    const step = 25;

    const result = {
        r: r + step < 255 ? r + step : 255,
        g: g + step < 255 ? g + step : 255,
        b: b + step < 255 ? b + step : 255,
    };

    return colorRGBToHex(result);
}

export function colorAddOpacity(color: HexColor, opacity: number): RGBAColor {
    if (!isHexValid(color)) {
        console.log('Failed to add opacity', color);
        throw hexIncorrect;
    }

    if (opacity < 0 || opacity > 1) {
        throw new Error('Wrong opacity value');
    }
    const rgb = colorHexToRGB(color);

    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
}
