export enum Availability {
    available = 'В наличии',
    waiting = 'Ожидается поступление',
}

type BedMock = {
    price: number;
    availability: Availability;
};

export const bedsMock: { [key: string]: BedMock } = {
    '1': {
        price: 13944,
        availability: Availability.available,
    },
    '2': {
        price: 7240,
        availability: Availability.available,
    },
    '3': {
        price: 6550,
        availability: Availability.waiting,
    },
    '4': {
        price: 1800,
        availability: Availability.waiting,
    },
};
