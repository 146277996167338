import React from 'react';
import { observer } from 'mobx-react';

import { flex } from '../styles';
import { useScreenWidth } from '../hooks';

import { catalogService } from '../../services/Catalog';

import { ItemCard } from './ItemCard';

export const CatalogPreview = observer(function CatalogPreview() {
    const preview = catalogService.catalogPreview;

    const { width } = useScreenWidth();

    const Wrapper = React.useMemo(() => {
        if (width == null) {
            return null;
        }
        if (width > 1146 && preview.length >= 3) {
            return (
                <div style={{ ...flex.rowCenter }}>
                    <ItemCard item={preview[0]} />
                    <ItemCard item={preview[1]} />
                    <ItemCard item={preview[2]} />
                </div>
            );
        }
        if (width > 764 && preview.length >= 2) {
            return (
                <div style={flex.rowCenter}>
                    <ItemCard item={preview[0]} />
                    <ItemCard item={preview[1]} />
                </div>
            );
        }
        return (
            <div style={flex.rowCenter}>
                <ItemCard item={preview[0]} />
            </div>
        );
    }, [preview, width]);

    return <div>{Wrapper}</div>;
});
