import React from 'react';

import { flex } from '../styles';

import { ButtonWrap } from '../Buttons';

type Props = {
    open: boolean;
    children: React.ReactNode;
    onClose: () => void;
};

export function Wrap(props: Props) {
    const { open, children, onClose } = props;

    const handleCancel = React.useCallback(() => {
        onClose();
    }, [onClose]);

    // disable close modal click for inner content
    const innerClick = React.useCallback((e: any) => {
        e.stopPropagation();
    }, []);

    return (
        <ButtonWrap
            onClick={handleCancel}
            style={{
                backgroundColor: 'rgba(1, 1, 1, 0.6)',
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 100,
                ...flex.column,
                overflow: 'auto',
                alignItems: 'center',
                paddingTop: 100,
                cursor: 'default',
                transform: open ? 'scale(1)' : 'scale(0)',
                transition: 'all .2s ease',
            }}
        >
            <div onClick={innerClick} onKeyDown={innerClick} role="button" tabIndex={-1}>
                {children}
            </div>
        </ButtonWrap>
    );
}
