import { observable, makeObservable, action } from 'mobx';

import { Availability, bedsMock } from '../constants';
import type { CatalogItemOptions } from '../types';

export class CatalogItemStore {
    public id: string;

    public art: string;

    public title: string;

    public descriptionPreview: string;

    public descriptionShort: string;

    public descriptionLong: string[];

    public preview: string;

    public images: string[];

    public marketPlaceLink: string;

    public price: number = 0;

    public availability: Availability = Availability.available;

    constructor(options: CatalogItemOptions) {
        const {
            id,
            art,
            title,
            descriptionPreview,
            descriptionShort,
            descriptionLong,
            preview,
            images,
            marketplaceLink,
        } = options;

        this.id = id;
        this.art = art;
        this.title = title;
        this.descriptionPreview = descriptionPreview;
        this.descriptionShort = descriptionShort;
        this.descriptionLong = descriptionLong;
        this.preview = preview;
        this.images = images;
        this.marketPlaceLink = marketplaceLink;

        makeObservable<CatalogItemStore, 'loadInfo'>(this, {
            price: observable,
            loadInfo: action,
        });

        this.loadInfo();
    }

    // getters
    get priceStr(): string {
        return this.price.toLocaleString('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });
    }

    // eslint-disable-next-line class-methods-use-this
    private loadInfo() {
        this.price = bedsMock[this.id]?.price ?? 0;
        this.availability = bedsMock[this.id]?.availability ?? Availability.available;
    }

    public openMarketplace() {
        if (this.marketPlaceLink) {
            window.open(this.marketPlaceLink, '_blank');
        }
    }
}
