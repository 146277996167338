// main logo
import logo from './sokolikaLogo.svg';

// cart
import cartIco from './cart-icon.svg';
import cartIco2 from './cart-icon2.svg';

// buttons
import ozon from './ozon_svg.svg';

// slider
import sliderBack from './sliderBack.png';
import slider1 from './gallery/slider/s1.jpg';
import slider2 from './gallery/slider/s2.jpg';
import slider3 from './gallery/slider/s3.jpg';
import slider4 from './gallery/slider/s4.jpg';

import photo1 from './testPhoto.png';

// prime double
import primeDoublePreview from './gallery/prime2/pPreview.jpg';
import primeD1 from './gallery/prime2/p1.jpg';
import primeD2 from './gallery/prime2/p2.jpg';
import primeD3 from './gallery/prime2/p3.jpg';
import primeD4 from './gallery/prime2/p4.jpg';

// prime
import primePreview from './gallery/prime/pPreview.jpg';
import prime1 from './gallery/prime/p1.jpg';
import prime2 from './gallery/prime/p2.jpg';

// albert
import albertPreview from './gallery/albert/aPreview.jpg';
import albert1 from './gallery/albert/a1.jpg';
import albert2 from './gallery/albert/a2.jpg';
import albert3 from './gallery/albert/a3.jpg';
import albert4 from './gallery/albert/a4.jpg';

// tarva
import tarvaPreview from './gallery/tarva/tPreview.jpg';
import tarva1 from './gallery/tarva/t1.png';
import tarva2 from './gallery/tarva/t2.png';

// benefits
import benefit1 from './benefit1.svg';
import benefit2 from './benefit2.svg';
import benefit3 from './benefit3.svg';

// certificate
import certificate from './certificate.jpg';

// modals
import cross from './cross.svg';

const primeDouble = [primeD1, primeD2, primeD3, primeD4];
const prime = [prime1, prime2];
const albert = [albert1, albert2, albert3, albert4];
const tarva = [tarva1, tarva2];

export {
    logo,
    cartIco,
    cartIco2,
    sliderBack,
    slider1,
    slider2,
    slider3,
    slider4,
    primeDoublePreview,
    primeDouble,
    prime,
    primePreview,
    albertPreview,
    albert,
    tarvaPreview,
    tarva,
    photo1,
    benefit1,
    benefit2,
    benefit3,
    ozon,
    certificate,
    cross,
};
