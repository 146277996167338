import type React from 'react';

export const flex = {
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    } as React.CSSProperties,
    column: {
        display: 'flex',
        flexDirection: 'column',
    } as React.CSSProperties,
    columnCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    } as React.CSSProperties,
    columnBottom: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    } as React.CSSProperties,
    row: {
        display: 'flex',
        flexDirection: 'row',
    } as React.CSSProperties,
    rowLeft: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
    } as React.CSSProperties,
    rowCenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    } as React.CSSProperties,
    rowSpread: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    } as React.CSSProperties,
};
