import React from 'react';

import { Header } from './Header';
import { Content } from './Content';

import type { Tab } from './types';

export function Tabs({ tabs }: { tabs: Tab[] }) {
    const [active, setActive] = React.useState(tabs[0]);

    const onHeaderClick = React.useCallback((tab: Tab) => {
        setActive(tab);
    }, []);

    return (
        <div>
            <Header tabs={tabs} active={active} onHeaderClick={onHeaderClick} />
            <Content tab={active} />
        </div>
    );
}

export type { Tab };
