import type React from 'react';

export const font = {
    xs: {
        fontStyle: 'normal',
        letterSpacing: 1,
        fontWeight: 300,
        fontSize: 12,
    } as React.CSSProperties,
    f13: {
        fontSize: 13,
    } as React.CSSProperties,
    s: {
        fontStyle: 'normal',
        letterSpacing: 1,
        fontWeight: 400,
        fontSize: 16,
    } as React.CSSProperties,
    f18: {
        fontSize: 18,
    } as React.CSSProperties,
    m: {
        fontStyle: 'normal',
        letterSpacing: 1,
        fontWeight: 700,
        fontSize: 20,
    } as React.CSSProperties,
    l: {
        fontStyle: 'normal',
        letterSpacing: 1,
        fontWeight: 700,
        fontSize: 24,
    } as React.CSSProperties,
    light: {
        fontWeight: 300,
    } as React.CSSProperties,
    heavy: {
        fontWeight: 700,
    } as React.CSSProperties,
};
