/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';

import { colorOffset } from '@lochmatij/modules-js.utilities';

import { Color, flex, margin, Sizes } from '../styles';

import { sliderContainer } from './containers';
import type { SliderImage } from './types';

type ItemProps = {
    image: SliderImage;
};

const baseSize = 8;

const Dot = observer(function Dot(props: ItemProps) {
    const { image } = props;
    const { changeImage, secondaryImage, progress } = sliderContainer;

    const width = React.useMemo(() => {
        if (image.key !== secondaryImage?.key) {
            return baseSize;
        }
        return baseSize + (baseSize * 5 * progress) / 100;
    }, [image.key, progress, secondaryImage?.key]);

    const color = React.useMemo(() => {
        if (image.key !== secondaryImage?.key) {
            return Color.Secondary;
        }
        return colorOffset(Color.Secondary, Color.Primary, progress);
    }, [image.key, progress, secondaryImage?.key]);

    const onClick = React.useCallback(() => {
        changeImage(image.key);
    }, [changeImage, image.key]);

    return (
        <div
            onClick={onClick}
            onKeyDown={onClick}
            style={{
                height: baseSize,
                width,
                borderRadius: baseSize / 2,
                backgroundColor: color,
                ...margin.horizontal(Sizes.XS),
            }}
        />
    );
});

const Dash = observer(function Dash() {
    const { progress } = sliderContainer;

    const width = React.useMemo(() => {
        return baseSize + (baseSize * 5 - (baseSize * 5 * progress) / 100);
    }, [progress]);

    const color = React.useMemo(() => {
        if (progress === 0) {
            return Color.Primary;
        }
        return colorOffset(Color.Primary, Color.Secondary, progress);
    }, [progress]);

    return (
        <div
            style={{
                height: baseSize,
                width,
                borderRadius: baseSize / 2,
                backgroundColor: color,
                ...margin.horizontal(Sizes.XS),
            }}
        />
    );
});

export const Switcher = observer(function Switcher() {
    const { imagesPool, primaryImage } = sliderContainer;

    const items = React.useMemo(() => {
        return imagesPool.map(img => {
            if (img.key === primaryImage?.key) {
                return <Dash key={img.key} />;
            }
            return <Dot image={img} key={img.key} />;
        });
    }, [imagesPool, primaryImage]);
    return <div style={{ ...flex.rowCenter, ...margin.all(Sizes.S) }}>{items}</div>;
});
