import React from 'react';

import { ButtonWrap } from './ButtonWrap';

type Props = {
    active?: boolean;
    onClick: () => void;
    style?: React.CSSProperties;
    title: string;
};

export function LinkButton(props: Props) {
    const { active, onClick, style, title } = props;

    const click = React.useCallback(() => {
        if (active != null && !active) {
            return;
        }
        onClick();
    }, [active, onClick]);

    return (
        <ButtonWrap
            onClick={click}
            style={{
                cursor: active ? 'pointer' : 'default',
                ...style,
            }}
        >
            {title}
        </ButtonWrap>
    );
}
