import React from 'react';
import { Layout } from 'antd';

import { Cart } from './Cart';
import { Logo } from './Logo';
import { Menu } from './Menu';
import { Phone } from './Phone';

import { isCartEnabled } from '../../services/Cart';

const { Header: HeaderBlock } = Layout;

export function Header() {
    return (
        <HeaderBlock>
            <Logo />
            <Menu />
            {isCartEnabled && <Cart />}
            <Phone />
        </HeaderBlock>
    );
}
