import React from 'react';

type Props = {
    id: string;
    width: number;
    height: number;
};

export function YouTubeVideo(props: Props) {
    const { id, width, height } = props;
    return (
        <iframe
            width={width}
            height={height}
            src={`https://www.youtube.com/embed/${id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    );
}
