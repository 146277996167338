import React from 'react';
import { Color, flex } from '../styles';

import { benefit1, benefit2, benefit3 } from '../../assets';

import { useElementWidth } from '../hooks';

import { BenefitItem } from './BenefitItem';

const benefits = {
    b1: {
        image: benefit1,
        text: 'Экологичные материалы',
    },
    b2: {
        image: benefit2,
        text: 'Легкая сборка по инструции',
    },
    b3: {
        image: benefit3,
        text: 'Гарантия качества, доступная цена',
    },
};

export function Benefits() {
    const { ref, width } = useElementWidth();

    const fontSize = React.useMemo(() => {
        if (width == null || width > 700) {
            return 24;
        }
        if (width > 550) {
            return 20;
        }
        if (width > 450) {
            return 16;
        }
        return 12;
    }, [width]);

    return (
        <div
            style={{
                width: '100%',
                height: 267,
                backgroundColor: Color.Grey,
                ...flex.center,
            }}
        >
            <div
                ref={ref}
                style={{
                    ...flex.rowCenter,
                    justifyContent: 'space-around',
                    width: '100%',
                    maxWidth: 1100,
                }}
            >
                <BenefitItem benefit={benefits.b1} fontSize={fontSize} />
                <BenefitItem benefit={benefits.b2} fontSize={fontSize} />
                <BenefitItem benefit={benefits.b3} fontSize={fontSize} />
            </div>
        </div>
    );
}
