import React from 'react';

type Props = {
    onClick: () => void;
    children: React.ReactNode;
    style?: React.CSSProperties;
};

export function ButtonWrap(props: Props) {
    const { onClick, children, style } = props;

    return (
        <div
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex={-1}
            style={{
                cursor: 'pointer',
                ...style,
            }}
        >
            {children}
        </div>
    );
}
