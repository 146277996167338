import { action, computed, makeObservable, observable } from 'mobx';

import type { CatalogItemStore } from '../Catalog';

import type { CartItem } from './types';

class CartService {
    public cart: { [id: string]: CartItem } = {};

    constructor() {
        makeObservable(this, {
            cart: observable,
            totalValue: computed,
            totalCount: computed,
            addToCart: action,
            removeFromCart: action,
            emptyCart: action,
        });
    }

    // Getters
    public get totalValue(): number {
        return Object.keys(this.cart).reduce((acc, key) => {
            const cartItem = this.cart[key];

            if (cartItem) {
                return acc + cartItem.item.price * cartItem.count;
            }
            return acc;
        }, 0);
    }

    public get totalCount(): number {
        return Object.keys(this.cart).reduce((acc, key) => {
            const cartItem = this.cart[key];

            if (cartItem) {
                return acc + cartItem.count;
            }
            return acc;
        }, 0);
    }

    // Actions
    public addToCart = (item: CatalogItemStore, count: number) => {
        const itemExisted = this.cart[item.id];

        if (itemExisted) {
            itemExisted.count += count;
        } else {
            this.cart[item.id] = {
                item,
                count,
            };
        }
    };

    public removeFromCart = (item: CatalogItemStore) => {
        const itemExisted = this.cart[item.id];

        if (itemExisted) {
            delete this.cart[item.id];
        }
    };

    public emptyCart = () => {
        this.cart = {};
    };
}

export const cartService = new CartService();
