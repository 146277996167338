import React from 'react';

import { YouTubeVideo } from './YouTubeVideo';

import { useElementWidth } from '../hooks';

import { photo1 } from '../../assets';
import { flex, font, margin, Sizes } from '../styles';

export function Video() {
    const { ref, width } = useElementWidth();

    const style = React.useMemo(() => {
        if (width == null || width > 1000) {
            return flex.rowCenter;
        }
        return flex.columnCenter;
    }, [width]);

    const videoSize = React.useMemo(() => {
        if (width == null || width > 800) {
            return {
                width: 715,
                height: 390,
            };
        }
        const k = (width * 0.9) / 715;
        return {
            width: width * 0.9,
            height: 390 * k,
        };
    }, [width]);

    return (
        <div
            ref={ref}
            style={{
                backgroundImage: `url(${photo1})`,
                height: 550,
                width: '100%',
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,.7)',
                    ...flex.columnCenter,
                }}
            >
                <div style={{ ...style, alignItems: 'center' }}>
                    <div
                        style={{
                            ...font.l,
                            fontSize: 36,
                            color: 'white',
                            maxWidth: 350,
                            ...margin.right(Sizes.L),
                            ...margin.bottom(Sizes.L),
                        }}
                    >
                        Видео-экскурсия на производство
                    </div>
                    <YouTubeVideo
                        id="8CDGtY7_28U"
                        width={videoSize.width}
                        height={videoSize.height}
                    />
                </div>
            </div>
        </div>
    );
}
