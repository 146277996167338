import React from 'react';

import { Color, flex, margin, padding, Sizes } from '../styles';

import up from './assets/up.svg';
import down from './assets/down.svg';

export function Counter() {
    const [count, setCount] = React.useState(1);

    const increase = React.useCallback(() => {
        setCount(count + 1);
    }, [count]);

    const decrease = React.useCallback(() => {
        if (count > 1) {
            setCount(count - 1);
        }
    }, [count]);

    return (
        <div
            style={{
                ...flex.row,
                alignItems: 'center',
                backgroundColor: Color.Grey,
                borderRadius: 6,
                ...padding.vertical(Sizes.XS),
                ...padding.horizontal(Sizes.S),
            }}
        >
            <div>{count}</div>
            <div
                style={{
                    ...flex.columnCenter,
                    ...margin.left(Sizes.S),
                }}
            >
                <div
                    onClick={increase}
                    onKeyDown={increase}
                    role="button"
                    tabIndex={-1}
                    style={{ width: 10, height: 10, cursor: 'pointer' }}
                >
                    <img
                        src={up}
                        alt="+1"
                        width={10}
                        height={10}
                        style={{ position: 'relative', top: 0 }}
                    />
                </div>
                <div
                    onClick={decrease}
                    onKeyDown={decrease}
                    role="button"
                    tabIndex={-1}
                    style={{ cursor: 'pointer' }}
                    // style={{ width: 10, height: 10 }}
                >
                    <img
                        src={down}
                        alt="-1"
                        width={10}
                        height={10}
                        // style={{ position: 'relative', top: 0 }}
                    />
                </div>
            </div>
        </div>
    );
}
