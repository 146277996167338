import { observable, computed, makeObservable, action } from 'mobx';

import { CatalogItemStore } from './stores';
import type { CatalogItemOptions } from './types';

import {
    prime,
    primePreview,
    primeDouble,
    primeDoublePreview,
    albert,
    tarva,
    tarvaPreview,
    albertPreview,
} from '../../assets';

const example: CatalogItemOptions = {
    id: '1',
    art: '6260',
    title: 'Двухъярусная кровать «Прайм»',
    descriptionPreview: 'Двухъярусная кровать «Прайм» из массива сосны',
    descriptionShort:
        'Двухъярусная кровать «Прайм» из массива сосны прекрасно подойдет под любую детскую комнату. Кровать изготовлена из цельных деталей из необработанного массива дерева. Древесина отличается высокой прочностью, безопасна для детского здоровья, не вызывает аллергии.',
    descriptionLong: [
        'В любой момент кровать можно самостоятельно перекрасить в другой цвет, а по окончании срока службы переработать. Удобно, экологично и безопасно для природы. Размеры двух спальных мест — 900 х 2000 мм. Оптимальные габариты для комфортного детского сна.',
        'Лестница. Ступеньки обеспечивают безопасный подъём к верхнему спальному месту. Защитные бортики. Верхнее спальное место дополнено бортами, предотвращающими падение ребёнка с кровати. Опоры из массива. Обеспечивают устойчивость конструкции, упрощают процесс уборки под кроватью.',
        'Максимальная нагрузка/спальное место 100кг, Максимальная толщина матраса 19см,Длина матраса 200 см, Ширина матраса 90см',
        'Дополнительная информация: Максимальная нагрузка обозначает статичную нагрузку, то есть вес, который кровать выдерживает, когда на ней лежат или сидят.',
        'Предупреждение: Не устанавливайте на кровати крючки и ручки и не привязывайте веревки или ремни, в которых дети могут запутаться. Это может стать причиной удушения. Высокие кровати, а также верхний ярус 2-ярусных кроватей и кроватей-чердаков не подходят для детей до 7 лет из-за опасности получения травм вследствие падения. Инструкция по сборке кровати в комплекте.',
    ],
    preview: primeDoublePreview,
    images: primeDouble,
    marketplaceLink:
        'https://www.ozon.ru/product/dvuhyarusnaya-krovat-praym-206h97h157-sm-721874208/?sh=Dqd-ycNNJA',
};

const example2: CatalogItemOptions = {
    id: '2',
    art: '6263',
    title: 'Кровать детская "Прайм"',
    descriptionPreview: 'Детская кровать «Прайм» из массива сосны',
    descriptionShort:
        'Детская кровать «Прайм» из массива сосны прекрасно подойдет под любую детскую комнату. Кровать изготовлена из цельных деталей из необработанного массива дерева. Древесина отличается высокой прочностью, безопасна для детского здоровья, не вызывает аллергии.',
    descriptionLong: [
        'Одноярусная кровать с бортами из массива сосны не займет много места в детской комнате. Простое и надёжное устройство спального места предполагает максимально комфортный сон вашего ребёнка. Кровать изготовлена из цельных деталей из массива дерева. Древесина отличается высокой прочностью, безопасна для детского здоровья, не вызывает аллергии. В любой момент кровать можно самостоятельно перекрасить в другой цвет, а по окончании срока службы переработать. Удобно, экологично и безопасно для природы.',
        'Размер спального места — 800 х 1600 мм. Оптимальные габариты для спокойного детского сна.',
        'Защитные бортики. Спальное место дополнено бортами, предотвращающими падение ребёнка с кровати.',
        'Высокие опоры из массива. Обеспечивают устойчивость конструкции, упрощают процесс уборки под кроватью.',
        'Материалы и характеристики: Детали кровати: массив сосны 19, 25 мм. Опоры: массив сосны 25 мм. Основание: ламели в комплекте. Нагрузка на спальное место: 70 кг. Высота от пола до спального места: 20,5 см. ',
        'Кровать предназначена для детей в возрасте от 6-7 лет. Рекомендуется использовать матрас с высотой не более 19 см. Матрас в комплект не входит, приобретается отдельно.',
    ],
    preview: primePreview,
    images: prime,
    marketplaceLink:
        'https://www.ozon.ru/product/odnospalnaya-krovat-praym-80h160-sm-793986215/?oos_search=false&sh=uqSmFG-MZg',
};

const example3: CatalogItemOptions = {
    id: '3',
    art: '003',
    title: 'Кровать "Тарва"',
    descriptionPreview: 'Кровати TARVA – современный взгляд на скандинавские традиции',
    descriptionShort:
        'Кровати TARVA – современный взгляд на скандинавские традиции: простой дизайн и необработанное дерево. Вечная классика и другие стили. Мебель можно обработать маслом, воском или лаком, а чтобы создать свой уникальный стиль, мебель можно покрасить.',
    descriptionLong: [
        'Естественные вариации цвета, рисунка и фактуры поверхности прочного массива сосны придают каждому предмету уникальную выразительность.',
        'Чтобы необработанная поверхность из массива дерева служила дольше, ее можно обработать лаком, воском, морилкой или маслом.',
        'Пространство под кроватью можно без труда почистить пылесосом.',
        'Под кроватью можно разместить ящики — отличное решение для хранения подушек и одеял.',
        'Каркас кровати ТАРВА — настоящий образец скандинавской традиции изготовления мебели. Это простой дизайн и использование необработанного массива дерева. Прекрасно сочетается с мебелью и предметами интерьера разных стилей.',
        'Размер в собранном виде: длина: 209 см, ширина: 168 см, высота изножья: 32 см, высота изголовья: 92 см, длина матраса: 200 см, ширина матраса: 160 см',
    ],
    preview: tarvaPreview,
    images: tarva,
    marketplaceLink: '',
};

const example4: CatalogItemOptions = {
    id: '4',
    art: '004',
    title: 'Стеллаж "Альберт"',
    descriptionPreview: 'Шкаф-полка из массива сосны.',
    descriptionShort: 'Шкаф-полка из массива сосны.',
    descriptionLong: [
        'Идеально подходит для хранения малогабаритных вещей, книг и украшений. Простая в сборке и качественная мебель. Лаконично впишется в любой интерьер.',
        'Инструкция по уходу: Протирать сухой тканью. Пятна можно удалить ластиком или тонкой шкуркой. Можно обработать маслом, воском, лаком или глазурованной краской, чтобы повысить износостойкость поверхности.',
        'Ширина : 64 см / Глубина : 28 см / Высота : 159 см. Вес : 7.33 кг (с упаковкой). Максимальная нагрузка: 25 кг (на одну полку)',
    ],
    preview: albertPreview,
    images: albert,
    marketplaceLink: '',
};

class CatalogService {
    public items: CatalogItemStore[];

    public active: string | undefined;

    constructor() {
        this.items = [
            new CatalogItemStore(example),
            new CatalogItemStore(example2),
            new CatalogItemStore(example3),
            new CatalogItemStore(example4),
        ];

        makeObservable(this, {
            active: observable,
            catalogPreview: computed,
            setActive: action,
            activeItem: computed,
        });
    }

    public getBedWithId(id: string): CatalogItemStore | undefined {
        return this.items.find(x => x.id === id);
    }

    public get catalogPreview(): CatalogItemStore[] {
        if (this.items.length <= 3) {
            return this.items;
        }

        if (this.active) {
            return this.items.filter(x => x.id !== this.active).slice(0, 3);
        }

        return [this.items[0], this.items[1], this.items[2]];
    }

    public get activeItem(): CatalogItemStore | undefined {
        if (!this.active) {
            return undefined;
        }

        return this.items.find(x => x.id === this.active);
    }

    public setActive(id: string | undefined) {
        this.active = id;
    }
}

export const catalogService = new CatalogService();
