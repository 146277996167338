import React from 'react';
import { observer } from 'mobx-react';

import { ozon } from '../../assets';
import { Color, flex, margin, Sizes } from '../styles';

import { Counter } from './Counter';
import { Button, ImageButton } from '../Buttons';

import { Availability, CatalogItemStore } from '../../services/Catalog';

enum Mode {
    Order = 'order',
    Market = 'market',
    Both = 'both',
}

const mode: Mode = Mode.Market;

type Props = {
    item: CatalogItemStore;
};

export const ToCart = observer(function ToCart(props: Props) {
    const { item } = props;

    const openMarketplace = React.useCallback(() => {
        item.openMarketplace();
    }, [item]);

    const orderMode = React.useMemo(() => {
        if (mode === Mode.Market) {
            return null;
        }

        return (
            <>
                <div style={{ ...margin.right(Sizes.M) }}>
                    <Counter />
                </div>
                <Button
                    onClick={() => console.log('Click-click')}
                    title="В корзину"
                    primaryColor={Color.Primary}
                    secondaryColor={Color.LightGrey}
                />
            </>
        );
    }, []);

    const marketMode = React.useMemo(() => {
        // if (mode === Mode.Order) {
        //     return null;
        // }

        return (
            <ImageButton
                onClick={openMarketplace}
                disabled={item.availability !== Availability.available}
                image={ozon}
                border
                primaryColor={Color.LightGrey}
                secondaryColor={Color.Grey}
            />
        );
    }, [item.availability, openMarketplace]);

    return (
        <div style={{ ...flex.rowCenter }}>
            {orderMode}
            {marketMode}
        </div>
    );
});
