import { base64toUtf, stringToBase64 } from './converter';

import type { StoredSession } from './types';

const keyPart = 'session';

class Sessions {
    public set<T>(name: string, value: T, secondsValid: number) {
        const key = this.getKey(name);
        const string = JSON.stringify(value);

        const session: StoredSession = {
            value: string,
            created: Date.now(),
            expire: Date.now() + secondsValid * 1000,
        };

        const sessionStringified = JSON.stringify(session);
        const valueToStore = stringToBase64(sessionStringified);

        localStorage.setItem(key, valueToStore);
    }

    public get<T>(name: string): T | null {
        const key = this.getKey(name);

        const stored = localStorage.getItem(key);
        if (!stored) {
            return null;
        }

        const session: StoredSession = JSON.parse(base64toUtf(stored)) as StoredSession;
        if (!session) {
            return null;
        }

        if (session.expire < Date.now()) {
            this.delete(key);
            return null;
        }

        return JSON.parse(session.value) as unknown as T;
    }

    public delete(name: string) {
        const key = this.getKey(name);

        localStorage.removeItem(key);
    }

    // eslint-disable-next-line class-methods-use-this
    private getKey(part: string): string {
        return `${keyPart}:${part}`;
    }
}

export const sessions = new Sessions();
