import React from 'react';

import { darkerThan } from '@lochmatij/modules-js.utilities';

import { Color, flex, font, padding, Sizes } from '../styles';
import type { Tab } from './types';

const styles = {
    common: {
        ...font.s,
        ...font.f13,
        ...font.heavy,
        ...padding.horizontal(Sizes.XL),
        ...padding.bottom(Sizes.M),
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6,
    } as React.CSSProperties,
    active: {
        backgroundColor: Color.LightGrey,
        ...padding.top(Sizes.XL),
    } as React.CSSProperties,
    inactive: {
        backgroundColor: darkerThan(Color.LightGrey),
        cursor: 'pointer',
        ...padding.top(Sizes.L),
    } as React.CSSProperties,
};

type Props = {
    tabs: Tab[];
    active: Tab;
    onHeaderClick: (tab: Tab) => void;
};

export function Header({ tabs, active, onHeaderClick }: Props) {
    const headers = React.useMemo(() => {
        return tabs.map(tab => {
            return (
                <div style={{ ...flex.columnBottom }} key={tab.key}>
                    <div
                        style={{
                            ...styles.common,
                            transition: 'all .5s ease',
                            ...(tab.key === active.key ? styles.active : styles.inactive),
                        }}
                        onClick={() => onHeaderClick(tab)}
                        onKeyDown={() => onHeaderClick(tab)}
                        role="button"
                        tabIndex={-1}
                    >
                        {tab.title}
                    </div>
                </div>
            );
        });
    }, [active.key, onHeaderClick, tabs]);

    return <div style={{ ...flex.row }}>{headers}</div>;
}
