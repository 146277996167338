import React from 'react';
import { font, margin, Sizes } from '../styles';

type Props = {
    benefit: {
        image: string;
        text: string;
    };
    fontSize: number;
};

export function BenefitItem(props: Props) {
    const { benefit, fontSize } = props;

    return (
        <div style={{ maxWidth: 250 }}>
            <img
                src={benefit.image}
                alt={benefit.text}
                height={86}
                style={margin.bottom(Sizes.XL)}
            />
            <div style={{ ...font.l, ...font.light, fontSize }}>{benefit.text}</div>
        </div>
    );
}
