import React, { ChangeEvent } from 'react';

import { colorAddOpacity } from '@lochmatij/modules-js.utilities';

import { Color, font, padding, Sizes } from '../styles';
import { InputSizes } from './constants';
import type { InputProps } from './types';

import './css/input.css';

export const TextInput = React.forwardRef(
    (props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
        const { style, size, focusColor, placeholder, value, error, onChange, onFocus, onBlur } =
            props;

        const [focused, setFocused] = React.useState(false);

        const focus = React.useCallback(() => {
            setFocused(true);
            if (onFocus) {
                onFocus();
            }
        }, [onFocus]);

        const blur = React.useCallback(() => {
            setFocused(false);
            if (onBlur) {
                onBlur();
            }
        }, [onBlur]);

        const computedStyle: React.CSSProperties = React.useMemo(() => {
            if (size === InputSizes.Large) {
                return {
                    height: 60,
                };
            }
            if (size === InputSizes.Small) {
                return {
                    height: 24,
                };
            }
            return {
                height: 40,
            };
        }, [size]);

        const shadows = React.useMemo(() => {
            if (!focusColor) {
                return Color.LightGrey;
            }
            return focused ? `0 0 5px ${colorAddOpacity(focusColor, 0.8)}` : undefined;
        }, [focusColor, focused]);

        const background = React.useMemo(() => {
            return error ? colorAddOpacity(Color.Error, 0.4) : style?.backgroundColor;
        }, [error, style?.backgroundColor]);

        return (
            <input
                ref={ref}
                onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
                placeholder={placeholder}
                value={value}
                type="text"
                style={{
                    borderRadius: 6,
                    transition: 'all 0.30s ease-in-out',
                    boxShadow: shadows,
                    ...font.s,
                    ...padding.horizontal(Sizes.M),
                    ...computedStyle,
                    ...style,
                    backgroundColor: background,
                }}
                onFocus={focus}
                onBlur={blur}
            />
        );
    },
);
