import React from 'react';
import { Layout } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import { useScreenWidth } from '../hooks';

import { ButtonWrap, LinkButton } from '../Buttons';

import { Color, flex, font, margin, padding, Sizes } from '../styles';

import { Routes, siteInfo } from '../../constants';

import { Modals, modalService } from '../../services/Modal';

const { Footer: FooterBlock } = Layout;

enum ComponentsType {
    Row = 'row',
    Column = 'column',
}

type Props = {
    type: ComponentsType;
};

const styles = {
    menuItem: {
        ...flex.row,
        ...margin.bottom(Sizes.XS),
    } as React.CSSProperties,
    addressItem: {
        ...margin.bottom(Sizes.XS),
        textAlign: 'left',
    } as React.CSSProperties,
    addressHeader: {
        ...font.heavy,
        display: 'inline',
    } as React.CSSProperties,
    phoneItem: {
        ...font.s,
        ...margin.top(Sizes.M),
    } as React.CSSProperties,
    blockCommon: {
        backgroundColor: Color.Primary,
        minWidth: '100%',
        ...padding.vertical(Sizes.XL),
        ...margin.vertical(Sizes.None),
    } as React.CSSProperties,
};

function Menu(props: Props) {
    const { type } = props;

    const navigate = useNavigate();
    const location = useLocation();

    const isRow = React.useMemo(() => {
        return type === ComponentsType.Row;
    }, [type]);

    const computedStyle = React.useMemo(() => {
        if (isRow) {
            return {
                justifyContent: 'center',
            };
        }
        return {
            justifyContent: 'start',
        };
    }, [isRow]);

    return (
        <div style={{ ...font.xs, ...margin.horizontal(Sizes.XL) }}>
            <div
                style={{
                    ...font.f13,
                    ...font.heavy,
                    ...margin.bottom(Sizes.M),
                    ...flex.row,
                    ...margin.top(isRow ? Sizes.XL : Sizes.None),
                    ...computedStyle,
                }}
            >
                Покупателю:
            </div>
            <LinkButton
                active
                title="Оплата"
                onClick={() =>
                    window.open(
                        'https://www.ozon.ru/seller/sokolika-585470/products/?miniapp=seller_585470',
                        '_blank',
                    )
                }
                style={{ ...styles.menuItem, ...computedStyle }}
            />
            <LinkButton
                active
                title="Доставка"
                onClick={() =>
                    window.open(
                        'https://www.ozon.ru/seller/sokolika-585470/products/?miniapp=seller_585470',
                        '_blank',
                    )
                }
                style={{ ...styles.menuItem, ...computedStyle }}
            />
            <LinkButton
                active={location.pathname !== Routes.About}
                title="О компании"
                onClick={() => navigate(Routes.About)}
                style={{ ...styles.menuItem, ...computedStyle }}
            />
            <LinkButton
                active={location.pathname !== Routes.Certificate}
                title="Сертификаты"
                onClick={() => navigate(Routes.Certificate)}
                style={{ ...styles.menuItem, ...computedStyle }}
            />
            <LinkButton
                active={location.pathname !== Routes.Policy}
                title="Политики"
                onClick={() => navigate(Routes.Policy)}
                style={{ ...styles.menuItem, ...computedStyle }}
            />
            <div style={{ ...margin.top(Sizes.XL), opacity: 0.5 }}>{siteInfo.copyright}</div>
        </div>
    );
}

function Address(props: Props) {
    const { type } = props;

    const isRow = React.useMemo(() => {
        return type === ComponentsType.Row;
    }, [type]);

    return (
        <div
            style={{
                ...font.xs,
                ...margin.right(Sizes.XL),
                ...margin.left(Sizes.XL),
                maxWidth: isRow ? 'none' : 200,
            }}
        >
            <div
                style={{
                    ...margin.bottom(Sizes.M),
                    ...flex.row,
                    ...font.f18,
                    ...font.heavy,
                    justifyContent: isRow ? 'center' : 'flex-start',
                }}
            >
                {siteInfo.title}
            </div>
            <div style={styles.addressItem}>
                <div style={styles.addressHeader}>Офис:&nbsp;</div>
                {siteInfo.addr1}
            </div>
            <div style={styles.addressItem} />
            {/* <div style={styles.addressItem}>
                <div style={styles.addressHeader}>Склад:&nbsp;</div>
                {siteInfo.addr2}
            </div> */}
        </div>
    );
}

function Contacts() {
    const showModal = React.useCallback(() => {
        modalService.show(Modals.PhoneCall);
    }, []);

    return (
        <div style={{ ...margin.horizontal(Sizes.XL) }}>
            <ButtonWrap onClick={showModal}>
                <div style={styles.phoneItem}>{siteInfo.phone}</div>
            </ButtonWrap>
            <div
                style={{
                    ...flex.row,
                    ...font.xs,
                    ...margin.top(Sizes.XL),
                }}
            >
                <div style={{ ...font.heavy }}>Эл. почта:&nbsp;</div>
                <LinkButton
                    active
                    title={siteInfo.mail}
                    onClick={() => window.open(`mailto:${siteInfo.mail}`)}
                />
            </div>
        </div>
    );
}

export function Footer() {
    const { width } = useScreenWidth();

    if (width < 650) {
        return (
            <FooterBlock
                style={{
                    ...styles.blockCommon,
                    ...flex.columnCenter,
                }}
            >
                <Address type={ComponentsType.Row} />
                <Contacts />
                <Menu type={ComponentsType.Row} />
            </FooterBlock>
        );
    }

    return (
        <FooterBlock
            style={{
                ...styles.blockCommon,
                ...flex.rowCenter,
            }}
        >
            <div
                style={{
                    ...flex.row,
                }}
            >
                <Menu type={ComponentsType.Column} />
                <Address type={ComponentsType.Column} />
                <Contacts />
            </div>
        </FooterBlock>
    );
}
