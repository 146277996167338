import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Routes } from '../../constants';
import { LinkButton } from '../Buttons';

export function Menu() {
    const navigate = useNavigate();
    const location = useLocation();

    const items = React.useMemo(() => {
        return [
            {
                label: 'Главная',
                key: 'main',
                onClick: () => {
                    if (location.pathname === Routes.Main) {
                        return;
                    }
                    navigate(Routes.Main);
                },
                active: location.pathname !== Routes.Main,
            },
            {
                label: 'Каталог',
                key: 'cat',
                onClick: () => {
                    if (location.pathname === Routes.Catalog) {
                        return;
                    }
                    navigate(Routes.Catalog);
                },
                active: location.pathname !== Routes.Catalog,
            },
            {
                label: 'О нас',
                key: 'about',
                onClick: () => {
                    if (location.pathname === Routes.About) {
                        return;
                    }
                    navigate(Routes.About);
                },
                active: location.pathname !== Routes.About,
                last: true,
            },
        ];
    }, [location.pathname, navigate]);

    const menu = React.useMemo(() => {
        return items.map(item => {
            return (
                <LinkButton
                    key={item.label}
                    title={item.label}
                    onClick={item.onClick}
                    style={{
                        paddingLeft: 8,
                        paddingRight: 8,
                        borderRightStyle: item.last ? 'none' : 'solid',
                        borderRightWidth: 1,
                        borderRightColor: '#AAAAAA',
                        cursor: item.active ? 'pointer' : 'default',
                    }}
                />
            );
        });
    }, [items]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                minHeight: 36,
                paddingLeft: 8,
                paddingRight: 8,
                marginRight: 12,
                borderRightStyle: 'solid',
                borderRightWidth: 1,
                borderRightColor: '#AAAAAA',
            }}
        >
            {menu}
        </div>
    );
}
