import React from 'react';

import { flex, margin, Sizes } from '../styles';

import { useScreenWidth } from '../hooks';

const wSize = 470;
const hSize = 470;

type Props = {
    images: string[];
};

type PropsMini = {
    image?: string;
    onClick: (image: string) => void;
    style?: React.CSSProperties;
    miniWidth: number;
    miniHeight: number;
};

function Mini(props: PropsMini) {
    const { image, onClick, style, miniWidth, miniHeight } = props;

    const click = React.useCallback(() => {
        onClick(image ?? '');
    }, [image, onClick]);

    if (!image) {
        return null;
    }

    return (
        <div onClick={click} onKeyDown={click} role="button" tabIndex={-1} style={style}>
            <img src={image} alt="mini" width={miniWidth} height={miniHeight} />
        </div>
    );
}

export function Gallery(props: Props) {
    const { images } = props;

    const { width } = useScreenWidth();

    const [main, setMain] = React.useState(images[0]);

    const switchImage = React.useCallback((newImage: string) => {
        setMain(newImage);
    }, []);

    const style: React.CSSProperties = React.useMemo(() => {
        if (images.length === 4) {
            return {};
        }
        return margin.right(Sizes.XL);
    }, [images.length]);

    const imageWidth = React.useMemo(() => {
        return Math.min(width, wSize);
    }, [width]);

    const imageHeight = React.useMemo(() => {
        return hSize / (wSize / imageWidth);
    }, [imageWidth]);

    return (
        <div style={{ maxWidth: wSize }}>
            <div
                style={{
                    width: '100%',
                    maxWidth: imageWidth,
                    height: '100%',
                    maxHeight: imageWidth,
                    ...margin.bottom(Sizes.L),
                }}
            >
                <img src={main} alt="Main" width={imageWidth} height={imageHeight} />
            </div>
            <div
                style={{
                    ...flex.row,
                    justifyContent: images.length === 4 ? 'space-between' : 'flex-start',
                    width: '100%',
                    ...margin.bottom(Sizes.S),
                }}
            >
                <Mini
                    image={images[0]}
                    onClick={switchImage}
                    miniWidth={imageWidth / 5}
                    miniHeight={imageHeight / 5}
                    style={style}
                />
                <Mini
                    image={images[1]}
                    onClick={switchImage}
                    miniWidth={imageWidth / 5}
                    miniHeight={imageHeight / 5}
                    style={style}
                />
                <Mini
                    image={images[2]}
                    onClick={switchImage}
                    miniWidth={imageWidth / 5}
                    miniHeight={imageHeight / 5}
                    style={style}
                />
                <Mini
                    image={images[3]}
                    onClick={switchImage}
                    miniWidth={imageWidth / 5}
                    miniHeight={imageHeight / 5}
                />
            </div>
        </div>
    );
}
