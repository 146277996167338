import { action, computed, makeObservable, observable } from 'mobx';

import { Async } from '@lochmatij/modules-js.utilities';

import type { SliderImage } from '../types';

export enum SlideDirection {
    Left = 1,
    Right = -1,
    None = 0,
}

class SliderContainer {
    public isSliding: boolean = false;

    public duration: number = 1000; // ms

    public progress: number = 0;

    public imagesPool: SliderImage[] = [];

    public primaryImage: SliderImage | undefined = undefined;

    public secondaryImage: SliderImage | undefined = undefined;

    constructor() {
        makeObservable<SliderContainer, 'setSliding' | 'setPrimary' | 'setSecondary'>(this, {
            isSliding: observable,
            duration: observable,
            progress: observable,
            primaryImage: observable,
            secondaryImage: observable,
            setSliding: action,
            setDuration: action,
            setProgress: action,
            setImages: action,
            setPrimary: action,
            setSecondary: action,
            primaryIndex: computed,
            secondaryIndex: computed,
        });
    }

    // Getters
    public get primaryIndex(): number {
        const x =
            this.primaryImage != null
                ? this.imagesPool.find(item => item.key === this.primaryImage?.key)
                : undefined;
        if (!x) {
            return -2;
        }
        return this.imagesPool.indexOf(x);
    }

    public get secondaryIndex(): number {
        const x =
            this.secondaryImage != null
                ? this.imagesPool.find(item => item.key === this.secondaryImage?.key)
                : undefined;
        if (!x) {
            return -2;
        }
        return this.imagesPool.indexOf(x);
    }

    public get direction(): SlideDirection {
        if (this.primaryIndex === this.secondaryIndex) {
            return SlideDirection.None;
        }

        return this.primaryIndex > this.secondaryIndex ? SlideDirection.Right : SlideDirection.Left;
    }

    // Setters
    private setSliding = (value: boolean) => {
        this.isSliding = value;
    };

    public setDuration = (value: number) => {
        this.duration = value;
    };

    public setProgress = (value: number) => {
        this.progress = value;
    };

    private setPrimary = (value: SliderImage) => {
        this.primaryImage = value;
    };

    private setSecondary = (value: SliderImage) => {
        this.secondaryImage = value;
    };

    // Actions
    public slide = async () => {
        this.setSliding(true);

        await this.delay();

        this.setSliding(false);
    };

    private delay = async () => {
        // value '145' experimentally counted
        await Async.timeout(this.duration / 145);

        this.setProgress(this.progress + 1);

        if (this.progress < 100) {
            await this.delay();
        } else {
            this.setProgress(0);
        }
    };

    // eslint-disable-next-line class-methods-use-this
    public changeImage = async (key: string) => {
        if (this.isSliding) {
            return;
        }

        const newSecondary = this.imagesPool.find(x => x.key === key);
        if (newSecondary == null) {
            return;
        }

        this.setSecondary(newSecondary);

        await this.slide();

        this.setPrimary(newSecondary);
    };

    public setImages = (value: SliderImage[]) => {
        if (value.length === 0) {
            return;
        }

        this.imagesPool = value;

        // important to set both image equal as default. So we get direction 'None'
        // eslint-disable-next-line prefer-destructuring
        this.primaryImage = this.imagesPool[0];
        // eslint-disable-next-line prefer-destructuring
        this.secondaryImage = this.imagesPool[0];
    };
}

export const sliderContainer = new SliderContainer();
