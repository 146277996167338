export enum Color {
    Primary = '#FFCC01',
    Secondary = '#C4C4C4',
    White = '#FFFFFF',
    Grey = '#F7F7F7',
    LightGrey = '#FBFBFB',
    Black = '#000000',
    LightBlack = '#434343',
    Error = '#D72323',
    Link = '#1A91FF',
}
