import React, { useEffect } from 'react';

import { Layout } from 'antd';

import { Header, Footer, flex, margin, Sizes, blockMaxWidth } from '../components';

const { Content } = Layout;

type Props = {
    children: React.ReactNode;
};

export function BasePagePattern({ children }: Props) {
    return (
        <Layout
            className="Layout"
            style={{
                minHeight: '100vh',
                ...flex.column,
            }}
        >
            <Header />
            <div
                style={{
                    minHeight: '100%',
                    ...flex.column,
                    flex: 1,
                    justifyContent: 'space-between',
                    ...margin.top(Sizes.XL),
                }}
            >
                <Content style={{ ...flex.center }}>
                    <div style={{ width: '100%', ...flex.columnCenter }}>{children}</div>
                </Content>
                <Footer />
            </div>
        </Layout>
    );
}

export function PagePattern({ children }: Props) {
    useEffect(() => {
        // scroll page to top, because sometimes after render u located at the middle
        window.scrollTo(0, 0);
    }, []);

    return (
        <BasePagePattern>
            <div
                style={{
                    maxWidth: blockMaxWidth,
                    width: '80%',
                }}
            >
                {children}
            </div>
        </BasePagePattern>
    );
}
