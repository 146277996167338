import React from 'react';

import { lighterThan, Async, darkerThan } from '@lochmatij/modules-js.utilities';

import { flex, padding, Sizes } from '../styles';

const clickAnimationTime = 2; // 200 ms
const baseAnimationTime = 5; // 500 ms

type Props = {
    onClick: () => void;
    image: string;
    primaryColor: string;
    secondaryColor: string;
    border?: boolean;
    paddingV?: Sizes;
    paddingH?: Sizes;
    imageHeight?: number;
    disabled?: boolean;
};

export function ImageButton(props: Props) {
    const {
        onClick,
        primaryColor,
        secondaryColor,
        image,
        border,
        paddingV,
        paddingH,
        imageHeight,
        disabled,
    } = props;

    const [focused, setFocused] = React.useState(false);
    const [hovered, setHovered] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);

    const click = React.useCallback(async () => {
        if (disabled) {
            return;
        }
        setClicked(true);
        await Async.timeout(clickAnimationTime * 100);
        onClick();
        setClicked(false);
    }, [disabled, onClick]);

    const background = React.useMemo(() => {
        if (disabled) {
            return primaryColor;
        }
        if (clicked) {
            return secondaryColor;
        }
        return hovered ? lighterThan(primaryColor) : primaryColor;
    }, [clicked, disabled, hovered, primaryColor, secondaryColor]);

    const borderStyle = React.useMemo(() => {
        const color = focused || border ? darkerThan(background) : background;
        return `1px solid ${color}`;
    }, [background, border, focused]);

    return (
        <div
            onClick={click}
            onKeyDown={click}
            role="button"
            tabIndex={-1}
            style={{
                ...flex.center,
                backgroundColor: background,
                opacity: disabled ? 0.4 : 1,
                transition: `all .${clicked ? clickAnimationTime : baseAnimationTime}s ease`,
                border: borderStyle,
                borderRadius: 6,
                ...padding.vertical(paddingV ?? Sizes.S),
                ...padding.horizontal(paddingH ?? Sizes.M),
                cursor: disabled ? 'default' : 'pointer',
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <img src={image} alt="button" height={imageHeight ?? 24} />
        </div>
    );
}
