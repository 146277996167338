import React from 'react';
import { font, InlineLink, margin, Sizes } from '../components';

import { PagePattern } from './PagePattern';

type Props = {
    children: React.ReactNode;
};

const styles = {
    title: {
        ...font.l,
    } as React.CSSProperties,
    section: {
        ...margin.vertical(Sizes.XL),
    } as React.CSSProperties,
    subtitle: {
        ...font.s,
        ...font.heavy,
        textAlign: 'left',
        ...margin.bottom(Sizes.M),
    } as React.CSSProperties,
    paragraph: {
        ...font.s,
        ...font.light,
        textAlign: 'left',
    } as React.CSSProperties,
};

function Section({ children }: Props) {
    return <div style={styles.section}>{children}</div>;
}

function Subtitle({ children }: Props) {
    return <div style={styles.subtitle}>{children}</div>;
}

function Paragraph({ children }: Props) {
    return <div style={styles.paragraph}>{children}</div>;
}

export function Policy() {
    return (
        <PagePattern>
            <div style={styles.title}>Политика конфиденциальности</div>
            <Section>
                <Paragraph>
                    АДМИНИСТРАЦИЯ САЙТА ГАРАНТИРУЕТ КОНФИДЕНЦИАЛЬНОСТЬ ДАННЫХ ПОСЕТИТЕЛЕЙ ДАННОГО
                    САЙТА; НАСТОЯЩИМ ИЗЛАГАЕТСЯ ПОЛИТИКА ОБРАЩЕНИЯ С ПЕРСОНАЛЬНЫМИ ДАННЫМИ
                    ПОЛЬЗОВАТЕЛЕЙ САЙТА. Настоящая Политика конфиденциальности (Политика в отношении
                    обработки персональных данных) (далее – Политика) подготовлена в соответствии с
                    п. 2 ч .1 ст. 18.1 Федерального закона Российской Федерации «О персональных
                    данных» № 152-ФЗ от 27 июля 2006 года (далее – 152-ФЗ) и определяет позицию ООО
                    «САУНДБЛОК» (далее – Компания) в области обработки и защиты персональных данных,
                    соблюдения прав и свобод каждого человека и, в особенности, права на
                    неприкосновенность частной жизни, личную и семейную тайну.
                </Paragraph>
                <Paragraph>
                    Настоящая Политика применяется по отношению ко всем персональным данным, которые
                    мы собираем или получаем оффлайн или онлайн, включая персональные данные,
                    собранные или предоставленные через наши веб-сайты и любые мобильные сайты,
                    приложения, виджеты и прочие интерактивные мобильные функции (совместно
                    именуемые как Приложения), а также с помощью электронных писем в формате HTML.
                    Посещая наши Сайты, Вы принимаете и соглашаетесь с правилами, описанными в
                    данной Политике. Если Вы не согласны с какими-либо условиями данной Политики,
                    пожалуйста, не пользуйтесь Сайтами или не предоставляйте никаких Данных.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>1. Какую информацию мы собираем?</Subtitle>
                <Paragraph>
                    Мы можем собирать, хранить и использовать следующие виды персональных данных:
                </Paragraph>
                <Paragraph>
                    А. Информация о Вашем компьютере и посещениях сайта, его использовании (включая
                    IP-адрес Вашего компьютера, географическое положение, тип и версия браузера,
                    операционная система, источник информации, продолжительность посещения,
                    просмотренные страницы и навигация сайта).
                </Paragraph>
                <Paragraph>
                    Б. Информация, относящаяся к любого рода операциям между нами и Вами на сайте
                    или по отношению к настоящему сайту, включая информацию о пользовании услугами
                    сайта.
                </Paragraph>
                <Paragraph>В. Информация, указываемая при регистрации на сайте.</Paragraph>
                <Paragraph>
                    Г. Информация, указанная при оформлении подписки на услуги, предоставляемые
                    сайтом, уведомления по электронной почте и новостные рассылки.
                </Paragraph>
                <Paragraph>
                    Д. Любая другая информация, которую Вы присылаете нам. Вы можете сохранить
                    Правила пользования сайтом и ознакомиться с ними.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>2. Файлы cookie</Subtitle>
                <Paragraph>
                    Файл cookie состоит из информации, присылаемой веб-сервером браузеру, и которая
                    хранится в браузере. Информация из браузера отсылается серверу каждый раз, когда
                    браузер запрашивает страницу с сервера. Это позволяет веб-серверу
                    идентифицировать и отслеживать данные браузера.
                </Paragraph>
                <Paragraph>
                    Мы используем Google Analytics и Яндекс.Метрику для анализа посещаемости
                    настоящего сайта. Google Analytics и Яндекс.Метрика формирует статистическую и
                    другую информацию о сайте, используя файлы-cookie, которые хранятся в
                    компьютерах пользователей. Эта информация используется для составления отчетов
                    об использовании сайта. Полученные данные хранятся в Google и Яндексе. Политику
                    конфиденциальности Google можно посмотреть на&nbsp;
                    <InlineLink
                        title="http://www.google.com/intl/ru/analytics/tos.html"
                        onClick={() =>
                            window.open(
                                'http://www.google.com/intl/ru/analytics/tos.html',
                                '_blank',
                            )
                        }
                    />
                    . Политику конфиденциальности Яндекса можно посмотреть на&nbsp;
                    <InlineLink
                        title="https://yandex.ru/legal/confidential/"
                        onClick={() =>
                            window.open('https://yandex.ru/legal/confidential/', '_blank')
                        }
                    />
                    .
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>3. Использование персональных данных пользователей</Subtitle>
                <Paragraph>
                    Персональная информация, полученная через сайт, может быть использована только в
                    целях, обозначенных в настоящей политике конфиденциальности или в
                    соответствующих разделах сайта. Администрация сайта может использовать
                    персональных данных, чтобы:
                </Paragraph>
                <Paragraph>А. Администрировать работу сайта.</Paragraph>
                <Paragraph>
                    Б. Улучшить работу браузера пользователя посредством персонализации сайта.
                </Paragraph>
                <Paragraph>В. Позволить пользователю пользоваться услугами сайта.</Paragraph>
                <Paragraph>
                    Г. Отправлять пользователю уведомления по электронной почте по специальному
                    запросу.
                </Paragraph>
                <Paragraph>
                    Д. Отправлять пользователю информацию, которая, с нашей точки зрения,
                    заинтересует его и может оказаться полезной, включая информацию об аналогичных
                    изделиях и услугах, так как он не отказался от такой рассылки во время общения с
                    нашим представителем.
                </Paragraph>
                <Paragraph>
                    Е. Сообщать третьим лицам статистическую информацию о пользователях сайта –
                    однако, исключено разглашение информации, позволяющей идентифицировать
                    конкретного пользователя.
                </Paragraph>
                <Paragraph>
                    Ж. Иметь дело с расследованиями и жалобами со стороны или в отношении
                    пользователя, только если затрагиваются интересы сайта. В соответствии со ст. 9
                    Федерального закона О персональных данных № 152-ФЗ от 27.07.2006, Вы даете
                    согласие Компании на обработку (включая сбор, систематизацию, накопление,
                    хранение, уточнение (обновление, изменение), распространение (трансграничную
                    передачу, передачу в целях исполнения договоров купли-продажи товаров),
                    обезличивание, блокирование, уничтожение) своих персональных данных, сообщаемых
                    в целях получения обратной связи консультационного и иного характера.
                </Paragraph>
                <Paragraph>
                    Администрация сайта гарантирует пользователю неразглашение его персональных
                    данных третьим лицам в целях прямого маркетинга без его письменного разрешения.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>4. Разглашение конфиденциальной информации</Subtitle>
                <Paragraph>
                    Конфиденциальная информация может быть разглашена только работникам,
                    сотрудникам, агентам, поставщикам, подрядчикам в той мере, в какой это
                    необходимо для достижения целей, обозначенных в политике конфиденциальности.
                </Paragraph>
                <Paragraph>Персональная информация может быть разглашена в случаях:</Paragraph>
                <Paragraph>А. Если это требуется по закону.</Paragraph>
                <Paragraph>Б. В связи с судопроизводством или возможной судебной тяжбой.</Paragraph>
                <Paragraph>
                    В. Чтобы установить, осуществить или защитить свои законные права (включая
                    сообщение информации третьим лицам в целях предотвращения акта мошенничества и
                    снижения кредитных рисков).
                </Paragraph>
                <Paragraph>
                    Г. Предоставить информацию любому лицу, которое, с точки зрения Администрации
                    сайта, может подать в суд или другие компетентные органы для разглашения там
                    этой персональной информации. Администрация сайта оставляет за собой право
                    разглашать конфиденциальную информацию только по решению суда.
                </Paragraph>
                <Paragraph>
                    За исключением приведенных выше случаев, Администрация сайта гарантирует
                    неразглашение конфиденциальных данных третьим лицам.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>5. Международная передача данных</Subtitle>
                <Paragraph>
                    Информация, собираемая сайтом, может храниться и обрабатываться, а также
                    перемещаться между странами, в которых функционирует сайт, чтобы позволить
                    Администрации сайта использовать информацию в соответствии с настоящей политикой
                    конфиденциальности. Информация, предоставляемая сайту, может передаваться
                    странам, которые не имеют законодательных актов о защите данных, эквивалентных
                    действующим в Европейском экономическом пространстве.
                </Paragraph>
                <Paragraph>
                    Более того, любая персональная информация, которая предоставляется для
                    публикации на сайте, публикуется в интернете и доступна по всему миру в сети
                    интернет. Администрация сайта не несет ответственности за использование этой
                    информации третьими лицами. Предоставляя такого рода сведения, пользователь
                    прямо соглашается на такой способ передачи персональной информации.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>6. Защита персональных данных пользователей</Subtitle>
                <Paragraph>
                    Администрация сайта обязуется применять технические и организационные меры в
                    целях предупреждения потери, неправомерного использования или искажения
                    персональных сведений о пользователе. Администрация сайта обязуется хранить все
                    персональных данных, предоставляемые пользователями, на защищенном сервере.
                    Разумеется, что передача данных через интернет, в сущности, небезопасна, в связи
                    с чем администрация сайта не может гарантировать полную сохранность данных,
                    пересылаемых через интернет.
                </Paragraph>
                <Paragraph>
                    Администрация сайта несет ответственность за хранение пароля пользователя, а
                    также другой информации о пользователе. Администрация не запрашивает пароль (за
                    исключением того случая, когда пользователь регистрируется на сайте). В случае,
                    если пользователь получает сообщение от имени администрации настоящего сайта с
                    просьбой ввести пользовательский пароль, следует удалить это сообщение, оставив
                    его без ответа, так как администрация сайта не запрашивает пароли. Такое
                    сообщение может содержать фишинговую ссылку, которая крадет пароли.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>7. Поправки</Subtitle>
                <Paragraph>
                    Администрация сайта оставляет за собой право время от времени вносить изменения
                    в настоящую Политику конфиденциальности и размещать обновленную версию на сайте.
                </Paragraph>
                <Paragraph>
                    Настоящая Политика может быть пересмотрена в любом из следующих случаев:
                </Paragraph>
                <Paragraph>
                    - при изменении законодательства Российской Федерации в области обработки и
                    защиты персональных данных;
                </Paragraph>
                <Paragraph>
                    - в случаях получения предписаний на устранение несоответствий, затрагивающих
                    область действия Политики;
                </Paragraph>
                <Paragraph>- по решению руководства Компании;</Paragraph>
                <Paragraph>- при изменении целей обработки персональных данных;</Paragraph>
                <Paragraph>
                    - при изменении организационной структуры, структуры информационных и/или
                    телекоммуникационных систем (или введении новых);
                </Paragraph>
                <Paragraph>
                    - при применении новых технологий обработки персональных данных (в т.ч.
                    передачи, хранения);
                </Paragraph>
                <Paragraph>
                    - при появлении необходимости в изменении процесса обработки персональных
                    данных, связанной с деятельностью Компании.
                </Paragraph>
                <Paragraph>
                    Любые изменения в настоящей Политике вступят силу с момента публикации настоящей
                    Политики на Сайте.
                </Paragraph>
                <Paragraph>
                    Использование Вами Сайтов после таких изменений означает, что Вы принимаете
                    измененную Политику.
                </Paragraph>
                <Paragraph>
                    О любых изменениях в политике конфиденциальности администрация Сайта может
                    сообщать по электронной почте.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>8. Права пользователя</Subtitle>
                <Paragraph>Пользователь имеет право получить от Компании:</Paragraph>
                <Paragraph>
                    - подтверждение факта обработки персональных данных и сведения о наличии
                    персональных данных, относящихся к соответствующему субъекту персональных
                    данных;
                </Paragraph>
                <Paragraph>
                    - сведения о правовых основаниях и целях обработки персональных данных;
                </Paragraph>
                <Paragraph>
                    - сведения о применяемых способах обработки персональных данных;
                </Paragraph>
                <Paragraph>- сведения о наименовании и местонахождении Компании;</Paragraph>
                <Paragraph>
                    - сведения о лицах, которые имеют доступ к данным или которым могут быть
                    раскрыты персональные данные на основании договора с Компанией или на основании
                    федеральных законов;
                </Paragraph>
                <Paragraph>
                    - перечень обрабатываемых персональных данных, относящихся к субъекту
                    персональных данных, и информацию об источнике их получения, если иной порядок
                    предоставления таких персональных данных не предусмотрен федеральными законами;
                </Paragraph>
                <Paragraph>
                    - сведения о сроках обработки персональных данных, в том числе о сроках их
                    хранения;
                </Paragraph>
                <Paragraph>
                    - сведения о порядке осуществления субъектом персональных данных прав,
                    предусмотренных 152-ФЗ;
                </Paragraph>
                <Paragraph>
                    - информацию об осуществляемой или о предполагаемой трансграничной передаче
                    персональных данных;
                </Paragraph>
                <Paragraph>
                    - наименование и адрес лиц, осуществляющих обработку персональных данных по
                    поручению Компании;
                </Paragraph>
                <Paragraph>
                    - иные сведения, предусмотренные 152-ФЗ или другими нормативно-правовыми актами
                    Российской Федерации;
                </Paragraph>
                <Paragraph>Пользователь имеет право потребовать от Компании:</Paragraph>
                <Paragraph>
                    - уточнения своих персональных данных, их блокирования или уничтожения в случае,
                    если персональные данные являются неполными, устаревшими, неточными, незаконно
                    полученными или не являются необходимыми для заявленной цели обработки;
                </Paragraph>
                <Paragraph>- отозвать свое согласие на обработку персональных данных;</Paragraph>
                <Paragraph>
                    - требовать устранения неправомерных действий Компании в отношении его
                    персональных данных;
                </Paragraph>
                <Paragraph>
                    - обжаловать действия или бездействие Компании в Роскомнадзор или в судебном
                    порядке в случае, если субъект персональных данных считает, что Компания
                    осуществляет обработку его персональных данных с нарушением требований
                    законодательства или иным образом нарушает его права и свободы;
                </Paragraph>
                <Paragraph>
                    - возмещения убытков и/или компенсацию морального вреда в судебном порядке для
                    защиты своих прав и законных интересов.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>9. Срок хранения</Subtitle>
                <Paragraph>Мы будем хранить Ваши персональные данные до:</Paragraph>
                <Paragraph>- достижения целей обработки Ваших персональных данных;</Paragraph>
                <Paragraph>
                    - выполнения условий договора, стороной которого, выгодоприобретателем или
                    поручителем, по которому являетесь Вы;
                </Paragraph>
                <Paragraph>
                    - Окончания срока, указанного в согласии на обработку персональных данных,
                    полученного от Вас.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>10. Сайты третьих лиц</Subtitle>
                <Paragraph>
                    Администрация настоящего сайта оставляет за собой право размещать ссылки на
                    сторонние ресурсы третьих лиц.
                </Paragraph>
                <Paragraph>
                    В настоящей Политике мы не рассматриваем и не несем ответственности за
                    конфиденциальность, информацию или другие аспекты деятельности любых третьих
                    лиц, включая любое третье лицо, управляющее любым сайтом, ссылка на который
                    содержится на сайтах Компании. Наличие ссылки на сайтах Компании не
                    подразумевает поддержку нами или нашими дочерними компаниями сайта, на который
                    ведет ссылка.
                </Paragraph>
                <Paragraph>
                    Кроме того, мы можем предоставить Вам доступ к функциональным возможностям
                    третьих сторон, которые позволяют Вам публиковать содержимое в Вашем(их)
                    аккаунте(ах) в социальных медиа. Обратите внимание, что любая информация,
                    предоставленная Вами путем использования таких функциональных возможностей,
                    управляется политикой конфиденциальности применимой третьей стороны, а не
                    настоящей Политикой. Мы не имеем возможности контролировать и не несем
                    ответственности за использование информации, предоставленной Вами путем
                    использования таких функциональных возможностей, любой третьей стороной.
                </Paragraph>
                <Paragraph>
                    Мы также не несем ответственности за сбор, использование и разглашение политики
                    и практики (включая политику безопасности данных) других организаций.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>11. Обновление информации</Subtitle>
                <Paragraph>
                    Пользователь должен своевременно информировать администрацию, если информацию,
                    которой располагает сайт о пользователе, необходимо корректировать или обновить.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>12. Антиспамная политика</Subtitle>
                <Paragraph>
                    В контексте передачи электронных сообщений, спам – это небольшие сообщения,
                    обычно рассылаемые в коммерческих целях.
                </Paragraph>
                <Paragraph>Мы проводим политику абсолютной нетерпимости спама.</Paragraph>
                <Paragraph>
                    В случае, когда Вы получаете сообщение от нас или отправленное посредством нашей
                    системы, которое напоминает спам, пожалуйста, незамедлительно сообщите об этом
                    по контактному адресу, указанному в пункте 13. Этот факт будет изучен.
                </Paragraph>
                <Paragraph>
                    Администрация сайта оставляет за собой право вносить изменения в антиспамную
                    политику в любое время. Обновленная версия будет публиковаться на сайте.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>13. Контактные данные</Subtitle>
                <Paragraph>
                    Если у вас возникли какие-либо вопросы о политике конфиденциальности,
                    анти-спамной политике или использовании персональной информации о пользователе,
                    Вы можете связаться с нами по контактным данным, указанным в соответствующем
                    разделе сайта.
                </Paragraph>
                <Paragraph>
                    В случае, если Вы хотите исключить любые данные о себе из нашей базы данных и
                    наложить запрет на их использование, то Вам необходимо послать запрос с
                    указанием данных, которые идентифицируют Вас.
                </Paragraph>
            </Section>
            <div style={{ ...margin.bottom(Sizes.XXXL) }}>&nbsp;</div>
        </PagePattern>
    );
}
