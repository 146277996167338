import type React from 'react';

import type { Sizes } from './constants';
import type { Offsets } from './types';

const base = 4;

function all(size: Sizes): React.CSSProperties {
    return {
        padding: base * size,
    };
}

function horizontal(size: Sizes): React.CSSProperties {
    return {
        paddingRight: base * size,
        paddingLeft: base * size,
    };
}

function vertical(size: Sizes): React.CSSProperties {
    return {
        paddingTop: base * size,
        paddingBottom: base * size,
    };
}

function left(size: Sizes): React.CSSProperties {
    return {
        paddingLeft: base * size,
    };
}

function right(size: Sizes): React.CSSProperties {
    return {
        paddingRight: base * size,
    };
}

function top(size: Sizes): React.CSSProperties {
    return {
        paddingTop: base * size,
    };
}

function bottom(size: Sizes): React.CSSProperties {
    return {
        paddingBottom: base * size,
    };
}

export const padding: Offsets = {
    all,
    horizontal,
    vertical,
    left,
    right,
    top,
    bottom,
};
