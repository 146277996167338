import React from 'react';

import { siteInfo } from '../../constants';

import { useScreenWidth } from '../hooks';

import { flex, font, margin, size, Sizes } from '../styles';
import { ButtonWrap } from '../Buttons';

import { modalService, Modals } from '../../services/Modal';

export function Phone() {
    const { width } = useScreenWidth();

    const showModal = React.useCallback(() => {
        modalService.show(Modals.PhoneCall);
    }, []);

    const small = React.useMemo(() => {
        return width < 650;
    }, [width]);

    return (
        <ButtonWrap
            style={{
                ...margin.all(Sizes.S),
                ...flex.columnBottom,
                ...size.l,
            }}
            onClick={showModal}
        >
            <div
                style={{
                    ...font.f18,
                    ...(small ? font.xs : font.heavy),
                    ...margin.bottom(Sizes.XS),
                }}
            >
                {siteInfo.phone}
            </div>
            <div
                style={{
                    ...font.xs,
                    ...font.heavy,
                    ...margin.bottom(Sizes.S),
                    ...margin.top(Sizes.XS),
                }}
            >
                {small ? 'Перезвоните мне' : 'Заказать обратный звонок'}
            </div>
        </ButtonWrap>
    );
}
