export * from './routes';

function copyright(startYear: number): string {
    const currentYear = new Date().getFullYear();
    return startYear === currentYear
        ? `© Sokolika ${startYear}`
        : `© Sokolika ${startYear}-${currentYear}`;
}

export const siteInfo = {
    phone: '+7 (911) 503-59-22',
    mail: 'tdsokolika@yandex.ru',
    title: 'ТД Соколика',
    addr1: 'Россия, г. Вологда, ул. Проспект Советский, д. 131а, оф. 4',
    addr2: 'Россия, г. Вологда, ул. Мира, д. 01, офис 1',
    copyright: copyright(2022),
};

export const about = {
    paragraph1:
        'Сокольская мебельная фабрика, расположенная в городе Сокол, в центральной части России, ведет свою деятельность с 1980 года.',
    paragraph2:
        'С 2004 года фабрика работала и заключала контракты со Шведской компанией IKEA. На протяжении почти 20 лет мы производили мебель, которая продавалась в магазинах IKEA по всему миру. В ассортимент производимых изделий входили детские и взрослые кровати, табуреты, комоды, полки и многие другие изделия из массива сосны.',
    paragraph3:
        'На данный момент наши дизайнеры занимаются разработкой новых позиций для увеличения ассортимента производимых товаров, которые смогут в дальнейшем поступить в продажу. Мы сами производим мебель, которую продаем, и гарантируем качество и надежность.',
};
