import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';

import { Main, About, Catalog, CatalogItem, Certificate, ModalPhone, Policy } from './pages';
import { Routes as SiteRoutes } from './constants';

import './styles/App.css';

function App() {
    const location = useLocation();
    const transitions = useTransition(location, {
        from: {
            opacity: 0,
            transform: 'translate3d(100%,0,0)',
        },
        enter: {
            opacity: 1,
            transform: 'translate3d(0%,0,0)',
        },
        leave: {
            opacity: 0,
            transform: 'translate3d(-100%,0,0)',
        },
    });

    return (
        <div className="App" style={{ position: 'relative' }}>
            {transitions((styles, item) => (
                <animated.div
                    style={{ ...styles, position: 'absolute', width: '100%' }}
                    key={item.key}
                >
                    <Routes location={item}>
                        <Route path={SiteRoutes.Main} element={<Main />} />
                        <Route path={SiteRoutes.About} element={<About />} />
                        <Route path={SiteRoutes.Catalog} element={<Catalog />} />
                        <Route path={SiteRoutes.CatalogItem} element={<CatalogItem />} />
                        <Route path={SiteRoutes.Certificate} element={<Certificate />} />
                        <Route path={SiteRoutes.Policy} element={<Policy />} />
                    </Routes>
                </animated.div>
            ))}
            <ModalPhone />
        </div>
    );
}

export default App;
