import React from 'react';

import { certificate } from '../assets';

import { AdaptiveImage, margin, Sizes } from '../components';

import { PagePattern } from './PagePattern';

export function Certificate() {
    return (
        <PagePattern>
            <div style={{ ...margin.bottom(Sizes.XL) }}>
                <AdaptiveImage src={certificate} />
            </div>
        </PagePattern>
    );
}
