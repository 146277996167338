import React from 'react';

export const useScreenWidth = () => {
    const [width, setWidth] = React.useState<number>(0);

    const resizeHandler = () => {
        const newWidth = window.innerWidth;
        setWidth(newWidth);
    };

    React.useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return { width };
};
