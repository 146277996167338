import type React from 'react';

export const size = {
    xxs: {
        height: 16,
    } as React.CSSProperties,

    xs: {
        height: 24,
    } as React.CSSProperties,

    s: {
        height: 36,
    } as React.CSSProperties,

    m: {
        height: 48,
    } as React.CSSProperties,

    l: {
        height: 60,
    } as React.CSSProperties,

    h100: {
        height: '100%',
    } as React.CSSProperties,
};
