import React from 'react';

import { TextInput } from './TextInput';

import type { InputProps } from './types';

const regex = /[^\d]/g;

const prefix = '+7'; // ru
const ruPhoneLength = 18; // ex. +7 (912) 345-67-89

type PhoneInputProps = Omit<InputProps, 'onChange'> & {
    onChange: (value: string, complete: boolean) => void;
};

// symbol to string helper
function sts(i: string): string {
    if (i == null) {
        return '';
    }
    return i.toString();
}

function formatPhone(x: string): string {
    const country = `${sts(x[0])}`;

    const code = `${sts(x[1])}${sts(x[2])}${sts(x[3])}`;
    if (code.length === 0) {
        return `+${country}`;
    }
    if (code.length < 3) {
        return `+${country} (${code}`;
    }

    const part1 = `${sts(x[4])}${sts(x[5])}${sts(x[6])}`;
    if (part1.length < 3) {
        return `+${country} (${code}) ${part1}`;
    }

    const part2 = `${sts(x[7])}${sts(x[8])}`;
    if (part2.length < 2) {
        return `+${country} (${code}) ${part1}-${part2}`;
    }

    const part3 = `${sts(x[9])}${sts(x[10])}`;

    return `+${country} (${code}) ${part1}-${part2}-${part3}`;
}

export function PhoneInput(props: PhoneInputProps) {
    const { style, size, focusColor, placeholder, error, value, onChange } = props;

    const [focused, setFocused] = React.useState(false);

    const onFocus = React.useCallback(() => {
        setFocused(true);
    }, []);

    const onBlur = React.useCallback(() => {
        setFocused(false);
    }, []);

    const checkPhone = React.useCallback(
        (inputValue: string) => {
            const reg = inputValue.replace(regex, '');
            const format = formatPhone(reg);

            if (inputValue.length < (value?.length ?? 0) && format === value) {
                const decreased = format.slice(0, format.length - 2);
                onChange(decreased, decreased.length === ruPhoneLength);
            }

            if (format !== value) {
                onChange(format, format.length === ruPhoneLength);
            }
        },
        [onChange, value],
    );

    const defaultValue = React.useMemo(() => {
        return value && value.length > 2 ? value : prefix;
    }, [value]);

    const showErr = React.useMemo(() => {
        if (focused) {
            return false;
        }
        return error;
    }, [error, focused]);

    return (
        <TextInput
            style={style}
            size={size}
            focusColor={focusColor}
            placeholder={placeholder}
            value={defaultValue}
            error={showErr}
            onChange={checkPhone}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
}
