import React from 'react';
import { observer } from 'mobx-react';

import { sliderContainer, SlideDirection } from './containers';

import {
    slider1,
    slider2,
    slider3,
    // slider4,
} from '../../assets';

import type { SliderImage } from './types';

const images: SliderImage[] = [
    {
        image: slider1,
        key: 'image1',
    },
    {
        image: slider2,
        key: 'image2',
    },
    {
        image: slider3,
        key: 'image3',
    },
    // {
    //     image: slider4,
    //     key: 'image4',
    // },
];

sliderContainer.setImages(images);

export const Photo = observer(function Photo() {
    const { primaryImage, secondaryImage, direction, isSliding, duration } = sliderContainer;

    const centralOffset = React.useMemo(() => {
        return 100 * direction;
    }, [direction]);

    const leftOffset = React.useMemo(() => {
        return direction === SlideDirection.Left ? 0 : -100 * direction;
    }, [direction]);

    const rightOffset = React.useMemo(() => {
        return direction === SlideDirection.Right ? 0 : 100 * direction;
    }, [direction]);

    const transition = React.useMemo(() => {
        return `all ${isSliding ? duration / 1000 - 0.2 : 0}s ease-in-out`;
    }, [duration, isSliding]);

    const primaryImg = React.useMemo(() => {
        return (
            <div
                style={{
                    position: 'relative',
                    transform: `translateX(${-1 * centralOffset}%)`,
                    transition,
                    opacity: '90%',
                }}
            >
                <img src={primaryImage?.image} alt="slider_photo" width="100%" />
            </div>
        );
    }, [centralOffset, primaryImage?.image, transition]);

    const rightImg = React.useMemo(() => {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    transform: `translateX(${100 - rightOffset}%)`,
                    transition,
                    opacity: '90%',
                }}
            >
                <img src={secondaryImage?.image} alt="slider_photo" width="100%" />
            </div>
        );
    }, [rightOffset, secondaryImage?.image, transition]);

    const leftImg = React.useMemo(() => {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    transform: `translateX(-${100 - leftOffset}%)`,
                    transition,
                    opacity: '90%',
                }}
            >
                <img src={secondaryImage?.image} alt="slider_photo" width="100%" />
            </div>
        );
    }, [leftOffset, secondaryImage?.image, transition]);

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
            }}
        >
            {primaryImg}
            {rightImg}
            {leftImg}
        </div>
    );
});
