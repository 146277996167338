import React from 'react';

import { Color, font, margin, padding, Sizes } from '../styles';
import type { Tab } from './types';

const styles = {
    title: {
        textAlign: 'left',
        ...font.s,
        ...font.f13,
        ...font.heavy,
        ...margin.vertical(Sizes.L),
    } as React.CSSProperties,
    paragraph: {
        textAlign: 'left',
        ...font.s,
        ...font.f13,
        ...font.light,
        ...margin.bottom(Sizes.M),
    } as React.CSSProperties,
};

export function Content({ tab }: { tab: Tab }) {
    const { title, paragraphs } = tab;

    const sections = React.useMemo(() => {
        return paragraphs.map(paragraph => {
            const key = paragraphs.indexOf(paragraph);
            return (
                <div style={{ ...styles.paragraph }} key={key}>
                    {paragraph}
                </div>
            );
        });
    }, [paragraphs]);

    return (
        <div
            style={{
                backgroundColor: Color.LightGrey,
                ...padding.all(Sizes.L),
                borderRadius: 6,
            }}
        >
            <div style={{ ...styles.title }}>{title}</div>
            {sections}
        </div>
    );
}
