import React from 'react';

export const useElementWidth = () => {
    const ref: any = React.useRef();
    const [width, setWidth] = React.useState<number>(0);

    const observer = React.useRef(
        new ResizeObserver(entries => {
            const { width: newWidth } = entries[0].contentRect;
            setWidth(newWidth);
        }),
    );

    React.useEffect(() => {
        observer.current.observe(ref.current);
    }, [ref, observer]);

    return { ref, width };
};
