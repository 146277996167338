/* eslint-disable @typescript-eslint/no-var-requires */
const BufferJS = require('buffer').Buffer;

export function bufferToBase64(buffer: typeof BufferJS): string {
    return buffer.toString('base64');
}

export function base64ToBuffer(base64: string): typeof BufferJS {
    return BufferJS.from(base64, 'base64');
}

export function stringToBase64(string: string): string {
    return bufferToBase64(BufferJS.from(string));
}

export function bufferToHex(buffer: typeof BufferJS): string {
    return buffer.toString('hex');
}

export function hexToBuffer(hex: string): typeof BufferJS {
    return BufferJS.from(hex, 'hex');
}

export function hexToString(hex: string): string {
    return hexToBuffer(hex).toString();
}

export function stringToHex(string: string): string {
    return bufferToHex(BufferJS.from(string));
}

export function base64toUtf(string: string): string {
    return base64ToBuffer(string).toString('utf8');
}
