import { action, computed, makeObservable, observable } from 'mobx';

export enum Modals {
    PhoneCall = 'phoneCall',
}

class ModalService {
    public modalStatus: { [modalName: string]: boolean };

    constructor() {
        this.modalStatus = {
            [Modals.PhoneCall]: false,
        };

        makeObservable(this, {
            modalStatus: observable,
            hideAll: action,
            show: action,
            phoneCallStatus: computed,
        });
    }

    public get phoneCallStatus(): boolean {
        return this.modalStatus[Modals.PhoneCall];
    }

    public hideAll = () => {
        const values = Object.values(Modals);

        values.forEach(value => {
            this.modalStatus[value] = false;
        });
    };

    public show = (modal: Modals) => {
        this.hideAll();
        this.modalStatus[modal] = true;
    };
}

export const modalService = new ModalService();
