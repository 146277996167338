import { Request } from './request';

export enum RequestTypes {
    Select = 'select',
    Update = 'update',
    Exist = 'exist',
}

const gateUrl = 'http://localhost/api/';

type GateApiParams = {
    type: RequestTypes;
    query: string;
};

export class GateApi {
    public static async isTableExist(tableName: string): Promise<boolean | undefined> {
        const form = GateApi.createForm({ type: RequestTypes.Exist, query: tableName });

        const response = await Request.post(gateUrl, form);
        return response?.exist !== null ? !!response?.exist : undefined;
    }

    public static async update(query: string): Promise<boolean> {
        const form = GateApi.createForm({ type: RequestTypes.Update, query });

        const response = await Request.post(gateUrl, form);
        return !!response?.updated;
    }

    public static async select<T>(query: string): Promise<T[]> {
        const form = GateApi.createForm({ type: RequestTypes.Select, query });

        const response = await Request.post(gateUrl, form);
        return response as unknown as T[];
    }

    // eslint-disable-next-line class-methods-use-this
    private static createForm(params: GateApiParams): FormData {
        return Request.createForm(params);
    }
}
