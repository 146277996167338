import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { sendMessageFromBot } from '@lochmatij/modules-js.utilities';

import { TG_BOT_UID, TG_CHANNEL } from '../configs';

import { cross } from '../assets';

import {
    Wrap,
    ImageButton,
    TextInput,
    PhoneInput,
    Button,
    ButtonSizes,
    margin,
    padding,
    flex,
    font,
    Color,
    Sizes,
    InlineLink,
} from '../components';

import { modalService } from '../services/Modal';

import { Routes } from '../constants';

const styles = {
    input: {
        width: 380,
        ...margin.vertical(Sizes.S),
        backgroundColor: Color.Grey,
        borderWidth: 0,
    } as React.CSSProperties,
};

export const ModalPhone = observer(function PhoneModal() {
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [phoneComplete, setPhoneComplete] = React.useState(false);

    const navigate = useNavigate();

    const nameRef = React.useRef<HTMLInputElement>(null);

    const onPhoneChange = React.useCallback((value: string, complete: boolean) => {
        setPhone(value);
        setPhoneComplete(complete);
    }, []);

    const closeModal = React.useCallback(() => {
        if (nameRef.current != null) {
            nameRef.current.value = '';
        }
        setName('');
        setPhone('');
        modalService.hideAll();
    }, []);

    const send = React.useCallback(async () => {
        await sendMessageFromBot(TG_BOT_UID, TG_CHANNEL, `Перезвонить: ${name} ${phone}`);
        closeModal();
    }, [closeModal, name, phone]);

    const sendDisabled = React.useMemo(() => {
        return name === '' || phone === '' || !phoneComplete;
    }, [name, phone, phoneComplete]);

    const showPolicy = React.useCallback(() => {
        navigate(Routes.Policy);
        closeModal();
    }, [closeModal, navigate]);

    return (
        <Wrap open={modalService.phoneCallStatus} onClose={closeModal}>
            <div
                style={{
                    backgroundColor: 'white',
                    position: 'relative',
                    borderRadius: 6,
                    ...padding.all(Sizes.XL),
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        right: 4,
                        top: 4,
                    }}
                >
                    <ImageButton
                        image={cross}
                        onClick={closeModal}
                        primaryColor={Color.White}
                        secondaryColor={Color.White}
                        border={false}
                        imageHeight={12}
                    />
                </div>
                <div
                    style={{
                        ...font.l,
                    }}
                >
                    Обратный звонок
                </div>
                <div
                    style={{
                        ...font.s,
                        color: Color.LightBlack,
                        ...margin.vertical(Sizes.L),
                        maxWidth: 400,
                    }}
                >
                    Укажите ваш телефон. Наш менеджер свяжется с вами в ближайшее время.
                </div>
                <div
                    style={{
                        ...flex.columnCenter,
                        ...margin.bottom(Sizes.L),
                    }}
                >
                    <TextInput
                        ref={nameRef}
                        style={styles.input}
                        focusColor={Color.LightBlack}
                        placeholder="Имя"
                        onChange={setName}
                    />
                    <PhoneInput
                        value={phone}
                        style={styles.input}
                        focusColor={Color.LightBlack}
                        placeholder="Телефон"
                        onChange={onPhoneChange}
                        error={phone.length > 2 && !phoneComplete}
                    />
                </div>
                <div style={{ ...flex.rowCenter }}>
                    <Button
                        title="Отправить"
                        onClick={send}
                        disabled={sendDisabled}
                        primaryColor={Color.Primary}
                        secondaryColor={Color.LightGrey}
                        innerPadding={ButtonSizes.Big}
                        height={ButtonSizes.Big}
                    />
                </div>
                <div
                    style={{
                        ...font.s,
                        ...font.f13,
                        color: Color.LightBlack,
                        ...margin.top(Sizes.L),
                        maxWidth: 400,
                        opacity: 1,
                    }}
                >
                    Нажимая Отправить, вы соглашаетесь с&nbsp;
                    <InlineLink title="Политикой конфиденциальности" onClick={showPolicy} />
                    &nbsp;сайта.
                </div>
            </div>
        </Wrap>
    );
});
